import { Component } from '@angular/core';

@Component({
  selector: 'app-whatsapp-banner',
  templateUrl: './whatsapp-banner.component.html',
  styleUrls: ['./whatsapp-banner.component.scss']
})
export class WhatsappBannerComponent {

}
