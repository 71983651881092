<section class="leader">

    <section class="image">
        <img src="assets/mascotes/personagemAmarelo.png" alt="">
    </section>
    <section class="text">
        <h2><strong>Assine nossa Newsletter</strong></h2>
        <h4>Fique por dentro de tudo que estamos fazemos</h4>
        <form>
            <mat-form-field>
                <input matInput placeholder="Digite seu e-mail" formControlName="email" required>
            </mat-form-field>
            <button type="submit" class="round background-black" mat-button (onsubmit)="onSubmit()">Assinar</button>
        </form>
    </section>
</section>