<section class="comunity">
    <section class="title">
        <h2>O que é uma Campanha
            Comunitária do Dia de Doar?</h2>
        <p>Campanhas Comunitárias do Dia de Doar são formadas quando pessoas de uma mesma região, causa ou identidade se
            reúnem
            para fazer a diferença na promoção da cultura de doação e na conexão entre pessoas e suas causas, de dentro
            das próprias
            comunidades. 
        </p>
        <p>
            Eles encontram maneiras criativas de mobilizar redes, organizar eventos voluntários, conscientizar sobre
            causas e
            arrecadar fundos para organizações sem fins lucrativos locais – gerando ondas de generosidade e inspirando
            mudanças.
        </p>
        <p>Os líderes comunitários do Dia de Doar envolvem parceiros em todos os setores da sociedade (empresas, poder
            público,
            escolas, igrejas, famílias, etc) para inspirar toda a comunidade a usar seu poder de generosidade radical
            para construir
            o mundo em que todos queremos viver.</p>
    </section>
    <section class="pictures">
        <mat-card>
            <mat-card-content>
                <img src="assets/mascotes/personagemAmarelo2.png" alt="">
            </mat-card-content>
            <mat-card-footer>
                <a class="round background-green"
                    href="https://drive.google.com/file/d/1PZOJwaQlDuRyAc4olspMHUu-tdvhSvr7/view?usp=sharing"
                    target="_blank" mat-button>
                    Guia de campanhas >
                </a>

            </mat-card-footer>
        </mat-card>

    </section>
</section>