import { Component } from '@angular/core';

@Component({
  selector: 'app-public-engage',
  templateUrl: './public-engage.component.html',
  styleUrls: ['./public-engage.component.scss']
})
export class PublicEngageComponent {

}
