<section class="principal-results">
    <section class="header">
        <h2>Principais resultados</h2>
        <p>
            Em apenas alguns anos, o GivingTuesday se tornou um movimento global com centenas de milhões de pessoas
            participando de
            todas as maneiras possíveis. Alguns resultados importantes do Dia de Doar 2023.
        </p>
    </section>

    <section class="cards" *ngIf="dashboardService.dashboard | async as dashboard">
        <mat-card *ngFor="let resultado of dashboard.resultados">
            <mat-card-header>
                <h2>
                    {{resultado.titulo}}
                </h2>
            </mat-card-header>
            <mat-card-content>{{resultado.descricao}}
                <!-- <p>{{timestampToDate(post.data) | date:'fullDate'}}</p>
                <h3>{{post.titulo}}</h3>
                <div [innerHTML]="post.conteudo"></div>
                <button mat-button>Ler mais</button> -->
            </mat-card-content>
        </mat-card>
    </section>