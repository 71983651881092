<mat-nav-list>
    <ng-container *ngFor="let page of pages">
        <ng-container *ngIf="!!page.link || !!page.href; else menulist">
            <a mat-list-item *ngIf="page.href" [href]="page.href" target="_blank">{{page.name}}</a>
            <mat-list-item *ngIf="page.link" [routerLink]="page.link">{{page.name}}</mat-list-item>
        </ng-container>
        <ng-template #menulist>
            <mat-expansion-panel class="expansion-panel mat-elevation-z0" dense>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        {{page.name}}
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <ng-container *ngFor="let item of page.itens">
                    <a mat-list-item *ngIf="item.href" [href]="item.href" target="_blank">{{item.name}}</a>
                    <mat-list-item *ngIf="item.link" [routerLink]="item.link">{{item.name}}</mat-list-item>
                </ng-container>
            </mat-expansion-panel>
        </ng-template>
    </ng-container>
</mat-nav-list>