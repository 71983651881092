import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { ClockService } from './shared/services/clock.service';
import { Timestamp } from '@angular/fire/firestore';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit {
  title = 'dia_de_doar';

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private clockService: ClockService
  ){
    this.matIconRegistry.addSvgIcon("header-icon", this.domSanitizer.bypassSecurityTrustResourceUrl('assets/header/Header-icon.svg'));
    this.matIconRegistry.addSvgIcon("facebook", this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/social/facebook.svg'));
    this.matIconRegistry.addSvgIcon("instagram", this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/social/instagram.svg'));
    this.matIconRegistry.addSvgIcon("linkedin", this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/social/linkedin.svg'));
    this.matIconRegistry.addSvgIcon("linkedin-amarelo", this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/social/linkedin-amarelo.svg'));
    this.matIconRegistry.addSvgIcon("twitter", this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/social/twitter.svg'));
    this.matIconRegistry.addSvgIcon("youtube", this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/social/youtube.svg'));;
    this.matIconRegistry.addSvgIcon("whatsapp", this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/social/whatsapp.svg'));;
  }

  ngOnInit(): void {
    this.clockService.getClock()
    this.clockService.localClock.subscribe(res=>{
      if(res){
        window.sessionStorage.setItem('lastUpdate', new Timestamp(res.lastUpdate.seconds, res.lastUpdate.nanoseconds).toDate().getTime().toString())
        window.sessionStorage.setItem('cacheDuration', res.cacheDuration)
      }

      // if(res) this.dDay = new Timestamp(res?.data?.seconds, res?.data?.nanoseconds).toDate()
    });

  }
}
