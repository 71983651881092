import { Component } from '@angular/core';

@Component({
  selector: 'app-people-and-co',
  templateUrl: './people-and-co.component.html',
  styleUrls: ['./people-and-co.component.scss']
})
export class PeopleAndCoComponent {

}
