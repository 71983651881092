import { Injectable } from '@angular/core';
import { DashboardService } from './dashboard.service';
import { Hero } from '../models/dashboard.model';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class BaseHeroService {

  public hero!: Hero;

  constructor(private dashboardService: DashboardService, private router: Router) {
    this.dashboardService.dashboard.subscribe(res=>{
      // console.log(res)

      if(res)this.hero = res.heros.find((hero: any) =>hero.pagina == router.url)
    })

   }
}
