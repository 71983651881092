import { Component } from '@angular/core';

@Component({
  selector: 'app-companies-how',
  templateUrl: './companies-how.component.html',
  styleUrls: ['./companies-how.component.scss']
})
export class CompaniesHowComponent {

}
