import { Component } from '@angular/core';
import { Timestamp } from '@angular/fire/firestore';
import { MatDialog } from '@angular/material/dialog';
import { SingleNewsComponent } from 'src/app/shared/components/single-news/single-news.component';
import { NewsPost } from 'src/app/shared/models/posts.model';
import { NewsCategoryLabel } from 'src/app/shared/services/category-news.service';
import { NewsService } from 'src/app/shared/services/news.service';

@Component({
  selector: 'app-blog-news',
  templateUrl: './blog-news.component.html',
  styleUrls: ['./blog-news.component.scss']
})
export class BlogNewsComponent {

  public NewsCategoryLabel = NewsCategoryLabel;

  constructor(public newsService:NewsService, public dialog: MatDialog){
    this.newsService.filterField = null;
    this.newsService.filterValue = null;
    this.newsService.resetLocal();
  }

  loadMorePosts(){
    this.newsService.getPosts();
  }

  public timestampToDate(ts: Timestamp){
    return new Timestamp(ts.seconds, ts.nanoseconds).toDate()
  }

  openDialog(post:NewsPost){
    this.dialog.open(SingleNewsComponent,
      {
      width: '95vw',
      maxWidth:'1440px',
      data: post,
    });
  }

  btoa(string: any){
    return btoa(String(string));
  }

}
