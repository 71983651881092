import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FaqComponent } from './faq.component';
import { SharedModule } from '../shared/shared.module';
import { FaqHeroComponent } from './components/faq-hero/faq-hero.component';
import { FaqListComponent } from './components/faq-list/faq-list.component';
import { MatExpansionModule } from '@angular/material/expansion';



@NgModule({
  declarations: [
    FaqComponent,
    FaqHeroComponent,
    FaqListComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    MatExpansionModule
  ]
})
export class FaqModule { }
