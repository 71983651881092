import { Component, ComponentRef, Input, OnInit } from '@angular/core';
import { CampaignCardComponent } from './campaign-card/campaign-card.component';
import { MainNewsService } from '../../services/main-news.service';

@Component({
  selector: 'app-campaigns',
  templateUrl: './campaigns.component.html',
  styleUrls: ['./campaigns.component.scss']
})
export class CampaignsComponent implements OnInit {

  constructor(private mainNewsService: MainNewsService){
      // this.mainNewsService.getPosts();

  }

  ngOnInit(): void {
  }

 slides: any;

//  [
//   {
//     component:CampaignCardComponent,
//     params:{
//       title: '#doarondônia 1',
//       image: 'https://blog.iprocess.com.br/wp-content/uploads/2021/11/placeholder.png',
//       link: 'https://google.com.br'
//     }
//   },
//   {
//     component:CampaignCardComponent,
//     params:{
//       title: '#doarondônia 2',
//       image: 'https://blog.iprocess.com.br/wp-content/uploads/2021/11/placeholder.png',
//       link: ''
//     }
//   },
//   {
//     component:CampaignCardComponent,
//     params:{
//       title: '#doarondônia 3',
//       image: 'https://blog.iprocess.com.br/wp-content/uploads/2021/11/placeholder.png',
//       link: ''
//     }
//   },
//   {
//     component:CampaignCardComponent,
//     params:{
//       title: '#doarondônia 4',
//       image: 'https://blog.iprocess.com.br/wp-content/uploads/2021/11/placeholder.png',
//       link: ''
//     }
//   },
//  ];
}
