import { Component } from '@angular/core';

@Component({
  selector: 'app-comunity-what-makes',
  templateUrl: './comunity-what-makes.component.html',
  styleUrls: ['./comunity-what-makes.component.scss']
})
export class ComunityWhatMakesComponent {

}
