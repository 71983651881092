import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-leader',
  templateUrl: './leader.component.html',
  styleUrls: ['./leader.component.scss']
})
export class LeaderComponent {

  constructor(private dialog: MatDialog){}

  formGroup = new FormGroup({
    email: new FormControl('')
  })


  onSubmit(){
    // this.dialog.open()
  }
}
