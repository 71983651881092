import { Component } from '@angular/core';

@Component({
  selector: 'app-ongs',
  templateUrl: './ongs.component.html',
  styleUrls: ['./ongs.component.scss']
})
export class OngsComponent {

}
