import { Component } from '@angular/core';
import { DepositionsService } from '../../services/depositions.service';
import { DepositionCardComponent } from './deposition-card/deposition-card.component';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-depositions',
  templateUrl: './depositions.component.html',
  styleUrls: ['./depositions.component.scss']
})
export class DepositionsComponent {
  public slides!: any

  constructor(public depositionsService:DepositionsService){
    this.depositionsService.Depositions$.pipe(
      map(res=> res.map(deposition=>{return {
        component:DepositionCardComponent,
        params:deposition
      }}))
      ).subscribe(res=>{
      this.slides = res
    })
  }
}
