<app-press-hero></app-press-hero>
<app-press-presentation></app-press-presentation>
<mat-divider></mat-divider>
<app-category-news></app-category-news>
<app-resources></app-resources>
<app-quick-facts></app-quick-facts>
<app-detail text="Quer conhecer mais detalhes?" subtitle=" Acesse nosso relatório de Atividades de 2022" route="/dados"
    label="Acessar relatório"></app-detail>
<app-media></app-media>
<app-leader></app-leader>
<app-donors></app-donors>