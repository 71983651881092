import { Component } from '@angular/core';

@Component({
  selector: 'app-data-hero',
  templateUrl: './data-hero.component.html',
  styleUrls: ['./data-hero.component.scss']
})
export class DataHeroComponent {

}
