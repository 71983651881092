import { Component, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';

export interface PageItem{
  name: string,
  link?: string,
  href?: string,
  itens?: PageItem[]
}

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent {
  @ViewChild('sidenav') sidenav!: MatSidenav;

  constructor(router: Router) {
    router.events.pipe(
      filter((event) => event instanceof NavigationEnd)
    ).subscribe(_ => this.sidenav.close());
  }

  public pages: PageItem[] = [
    {
      name: 'Conheça',
      itens: [
        {
          name: 'Sobre nós',
          link: 'sobre'
        },
        {
          name: 'Embaixadores',
          link: 'embaixadores'
        },
        // {
        //   name: 'Apoiadores',
        //   link: ''
        // },
        {
          name: 'Notícias e imprensa',
          link: 'imprensa'
        },
        {
          name: 'Conheça nossa Teoria da Mudança',
          href: 'https://drive.google.com/file/d/1TVCxvx6dCrLp-mS_CXFgo51AM_V5c5bx/view?usp=sharing'
        }
        // {
        //   name: 'Transparência',
        //   link: ''
        // },
      ]
    },
    {
      name: 'Participe',
      itens: [
        {
          name: 'Registre sua campanha comunitária',
          link: 'inscreva-se'
        },
        // {
        //   name: 'Inspire-se',
        //   link: 'inspire-se'
        // },
        {
          name: 'Para ONGs',
          link: 'ongs'
        },
        // {
        //   name: 'Para comunidades',
        //   link: ''
        // },
        {
          name: 'Para empresas',
          link: 'empresas'
        },
        {
          name: 'Gestão pública',
          link: 'gestao-publica'
        },
        {
          name: 'Pessoas, famílias e amigos',
          link: 'pessoas-e-cia'
        },
      ]
    },
    {
      name: 'Informações',
      itens:[
        {
          name: 'Blog',
          link: 'blog'
        },
        {
          name: 'Dados',
          link: 'dados'
        },
        {
          name: 'Resultados',
          link: 'resultados'
        },
        {
          name: 'FAQ',
          link: 'faq'
        },
      ]
    },
    {
      name: 'Campanhas',
      itens: [
        {
          name: 'Campanhas comunitárias',
          link: 'campanhas'
        },
        {
          name: 'Minha História de Doação',
          link: 'inspire-se'
        },
      ]
    },
  ]
}
