import { Component } from '@angular/core';
import { FaqService } from '../../services/faq.service';

@Component({
  selector: 'app-common-questions',
  templateUrl: './common-questions.component.html',
  styleUrls: ['./common-questions.component.scss']
})
export class CommonQuestionsComponent {

  constructor(public faqService: FaqService){}
}
