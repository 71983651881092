<section class="what">
    <section class="left-container">
        <h2>Como empresas e marcas
            podem alavancar o Dia de Doar?</h2>
        <ul>
            <li>Aproveite seu programa de doações correspondentes e faça uma ação de matchfunding para as doações dos
                funcionários à
                organizações sem fins lucrativos
            </li>
            <li>Organize um dia de voluntariado no Dia de Doar</li>
            <li>Comprometa-se a doar tempo/conhecimento pro bono para uma organização sem fins lucrativos </li>
            <li>Combine as doações que os consumidores fazem para uma instituição sem fins lucrativos ou causa
                específica que esteja
                alinhada com sua estratégia de ESG</li>
            <li>Doe uma parte das vendas da Black Friday para uma organização pequena e menos conhecida
            </li>
            <li>Se for o caso, doe produtos. Por exemplo, doe laptops para uma escola ou alimentos para o banco de
                alimentos local </li>
            <li>
                Lance uma campanha semanal de doações às terças-feiras e mantenha vivo o espírito do Dia de Doar o ano
                todo
            </li>
            <li>Seja criativo! Confira nossos estudos de caso abaixo para obter mais inspiração e sinta-se à vontade
                para nos enviar um
                e-mail se precisar de ajuda!</li>
        </ul>

        <!-- <button class="round background-light-blue" mat-button> Conhecer mais ></button> -->
    </section>
    <section class="right-container">
        <mat-card>
            <mat-card-content>
                <h4>Lembrar de:</h4>
                <ul>
                    <li>
                        Compartilhe seus planos com a equipe do Dia de Doar para que possamos ajudar a impulsionar seus
                        esforços! Inscreva-se
                        aqui
                    </li>
                    <li>
                        Crie buzz com antecedência, convidando clientes e consumidores a participar de sua campanha.
                        Envie um release à imprensa
                        e um alerta de mídia.
                    </li>
                    <li>
                        Compartilhe o sucesso do Dia de Doar na sua empresa com postagens nas redes sociais, boletins
                        informativos da empresa,
                        em sites de notícia e até mesmo em qualquer outra comunicação com seus consumidores. Isso ajuda
                        a espalhar a notícia e
                        pode levar a um impacto ainda maior no próximo ano!
                    </li>
                </ul>
                <a mat-button
                    href="https://relacionamento.captadores.org.br/ddd-lp-perene-empresas-download-de-materiais"
                    target="_blank" class="round background-dark-blue">Baixe os recursos ></a>
            </mat-card-content>
        </mat-card>
    </section>
</section>