<section class="about-hero">
    <section class="title">
        <h1 class="text-box">
            <span class="green">
                Sobre
            </span>
            o Dia de Doar
        </h1>
        <p>O Dia de Doar faz parte do maior movimento de incentivo à generosidade do mundo, o GivingTuesday.</p>
    </section>
    <section class="pictures">
        <img src="assets/banners/banner_sobre_nos.png" alt="picture">
    </section>
</section>