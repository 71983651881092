import { Component, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-b64-input',
  templateUrl: './b64-input.component.html',
  styleUrls: ['./b64-input.component.scss']
})
export class B64InputComponent {

  @Input() formControl!: FormControl;
  @Input() label!: string;
  fileName: string = '';

  changeFile(event: any){
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onloadend= ()=>{
      const base64String = reader.result as string;
      // console.log(file)
      this.fileName = file.name
      this.formControl.setValue(base64String);
    }

    if(file) {
      reader.readAsDataURL(file)
    }
  }
}
