<mat-toolbar [class.mat-elevation-z3]="true">
    <div class="header-start" *ngIf="dDay" routerLink="home">
        <mat-icon class="header-icon" svgIcon="header-icon"></mat-icon>
        <app-day-preview [date]="dDay"></app-day-preview>
    </div>

    <div class="page-list">
        <ng-container *ngFor="let page of pages">
            <ng-container *ngIf="!!page.link || !!page.href; else menulist">
                <a mat-button *ngIf="page.href" [href]="page.href" target="_blank">{{page.name}}</a>
                <mat-button *ngIf="page.link" [routerLink]="page.link">{{page.name}}</mat-button>
            </ng-container>
            <ng-template #menulist>
                <button mat-button [matMenuTriggerFor]="appMenu" [matMenuTriggerData]="page">
                    {{page.name}}
                    <mat-icon iconPositionEnd>expand_more</mat-icon>
                </button>

            </ng-template>
        </ng-container>
    </div>

    <div class="page-list">
        <a mat-button class="round background-light-yellow"
            href="https://relacionamento.captadores.org.br/ddd-lp-perene-ddd24-download-de-materiais"
            target="_blank">Materiais para download</a>
        <a mat-button class="round background-light-yellow" [routerLink]="['/ongs']">Para quem doar</a>
        <a mat-button class="round background-light-blue" [routerLink]="['/inscreva-se']">Cadastro</a>
    </div>

    <button mat-icon-button class="menu" aria-label="Example icon-button with menu icon" (click)="toggleMenu.emit()">
        <mat-icon>menu</mat-icon>
    </button>
</mat-toolbar>

<mat-menu #appMenu="matMenu" class="mat-menu">
    <ng-template matMenuContent let-aliasItens="itens">
        <ng-container *ngFor="let item of aliasItens">
            <a mat-menu-item *ngIf="item.href" [href]="item.href" target="_blank">{{item.name}}</a>
            <button mat-menu-item *ngIf="item.link" [routerLink]="item.link">{{item.name}}</button>
        </ng-container>
    </ng-template>
</mat-menu>