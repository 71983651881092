<section class="what">
    <section class="left-container">
        <img src="assets/banners/banner_empresas_fatos_rapidos.png" alt="">

        <!-- <button class="round background-light-blue" mat-button> Conhecer mais ></button> -->
    </section>
    <section class="right-container">
        <h2>Fatos rápidos sobre o Dia de Doar
            nas empresas</h2>
        <p>O Dia de Doar trabalha com parceiros em todos os setores e fronteiras para entender os motivadores e impactos
            da
            generosidade, explorar comportamentos e padrões de doação e usar dados para inspirar mais doações em todo o
            mundo.
            Através do
            <a href="https://www.givingtuesday.org/data-commons" target="_blank">GivingTuesday Data Commons</a>
            , identificamos e compartilhamos práticas inovadoras que podem ajudar a
            aumentar a
            generosidade. Com mais de 60 parceiros contribuintes e 40 laboratórios de dados globais, a iniciativa é a
            maior
            colaboração filantrópica de dados já construída.
        </p>
        <p>Em relação à participação da marca no Dia de Doar, aprendemos:</p>

        <ul>
            <li>Os consumidores têm um desejo maior de contribuir para causas sociais durante as festas de fim de ano.
            </li>
            <li>Marcas que se ativam no Dia de Doar obtêm cobertura de mídia extremamente favorável e grande engajamento
                online</li>
        </ul>
    </section>
</section>