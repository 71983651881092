<section class="how-it-started">
    <section class="title">
        <h2>Engaje toda a comunidade
            para se tornar doadora!</h2>
        <p>Como campanha de conscientização, nosso maior desafio é alcançar o maior número de pessoas possível e, assim,
            aumentar o
            volume de doações na celebração da doação, o #GivingTuesday Brasil - ou como a gente prefere, Dia de Doar.
        </p>
        <p>
            Que tal implementar uma ação dentro do seu espaço de atuação, como dentro de Conselhos, Fundos Sociais,
            implementando o
            projeto de lei do Dia de Doar oficialmente no calendário da cidade, engajando prefeituras ou incluindo a
            pauta em
            programas de capacitação para organizações?
        </p>
    </section>
    <section class="pictures">
        <a href="https://docs.google.com/document/d/12AtCt3pBKFXO9aqkvCUKcPhDElyAjmOM/edit?usp=sharing&ouid=117988431013441739014&rtpof=true&sd=true "
            target="_blank">
            <mat-card>
                <mat-card-content>
                    <img src="assets/icons/resources/logos.png" alt="">
                </mat-card-content>
                <mat-card-footer>
                    <p>Acesse o modelo de projeto de lei do Dia de Doa</p>
                </mat-card-footer>
            </mat-card>
        </a>
        <a href="https://drive.google.com/drive/folders/17VrP_FoSEWIivVGuCBMxluHeNjVvmeS3?usp=sharing " target="_blank">
            <mat-card>
                <mat-card-content>
                    <img src="assets/icons/resources/logos.png" alt="">
                </mat-card-content>
                <mat-card-footer>
                    <p>Confira algumas ideias de como você pode se envolver!</p>

                </mat-card-footer>
            </mat-card>
        </a>
        <a href="https://youtube.com/live/llPZApR0ii4" target="_blank">
            <mat-card>
                <mat-card-content>
                    <img src="assets/icons/resources/logos.png" alt="">
                </mat-card-content>
                <mat-card-footer>
                    <p>Confira como algumas cidades já fizeram</p>

                </mat-card-footer>
            </mat-card>
        </a>
        <a href="https://www.gentilezagenerosidade.org.br/" target="_blank">

            <mat-card>
                <mat-card-content>
                    <img src="assets/icons/resources/logos.png" alt="">
                </mat-card-content>
                <mat-card-footer>
                    <p>Apresente ferramentas pedagógicas para as escolas da cidade</p>

                </mat-card-footer>
            </mat-card>
        </a>
        <a [routerLink]="['/inscreva-se']">
            <mat-card>
                <mat-card-content>
                    <img src="assets/icons/resources/logos.png" alt="">
                </mat-card-content>
                <mat-card-footer>
                    <p>Compartilhe sua iniciativa</p>

                </mat-card-footer>
            </mat-card>
        </a>
        <a [routerLink]="['/inscreva-se']">
            <mat-card>
                <mat-card-content>
                    <img src="assets/icons/resources/logos.png" alt="">
                </mat-card-content>
                <mat-card-footer>
                    <p>Confira se organizações estão se mobilizando na sua região pelo Mapa da Generosidade!</p>

                </mat-card-footer>
            </mat-card>
        </a>
    </section>
</section>