<section class="resources">
    <h2>Recursos & Mídia</h2>
    <div class="cards">
        <a href="https://drive.google.com/drive/folders/1KASn0n91dZegJSVAcCc-QwV-jW0gz8lB?usp=sharing" target="_blank">
            <mat-card>
                <mat-card-content>
                    <img src="assets/icons/resources/release.png" alt="picture">
                    <p>Release</p>
                    <!-- <img src="assets/mascotes/como-comecou.png" alt=""> -->
                </mat-card-content>
            </mat-card>
        </a>
        <a href="https://drive.google.com/drive/folders/1KASn0n91dZegJSVAcCc-QwV-jW0gz8lB?usp=sharing" target="_blank">
            <mat-card>
                <mat-card-content>
                    <img src="assets/icons/resources/fotos.png" alt="picture">
                    <p>Fotos e vídeos</p>
                </mat-card-content>
            </mat-card>
        </a>
        <a href="https://drive.google.com/drive/folders/1KASn0n91dZegJSVAcCc-QwV-jW0gz8lB?usp=sharing" target="_blank">
            <mat-card>
                <mat-card-content>
                    <img src="assets/icons/resources/logos.png" alt="picture">
                    <p>Logos</p>
                </mat-card-content>
            </mat-card>
        </a>

        <a target="_blank" [routerLink]="['/embaixadores']">
            <mat-card>
                <mat-card-content>
                    <img src="assets/icons/resources/porta-vozes.png" alt="picture">

                    <p>Porta-vozes</p>
                </mat-card-content>
            </mat-card>
        </a>
    </div>
</section>