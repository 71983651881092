<section class="results">
    <section class="header">
        <h2>Conheça os resultados do Dia de Doar no Brasil e
            <span class="yellow">
                inspire-se
            </span>
            para

            <span class="green">
                continuar
            </span>
            crescendo!
        </h2>
        <a mat-button class="round background-light-yellow "
            href="https://docs.google.com/spreadsheets/d/1H36JF_Y3LlD-wbjPek8iDYeg1MhW4oEg6vCybEDpAxE/edit?usp=sharing"
            target="_blank">Ver histórico
            completo</a>
    </section>
    <section class="cards">
        <div class="card" *ngFor="let result of resultsService.results$ | async">
            <h3>
                <strong>
                    {{result.ano}}
                </strong>
            </h3>
            <mat-card>
                <mat-card-content>
                    <span class="title">Doações On-line</span>
                    <span class="content">{{result.doacoes}}</span>
                    <span class="title">Campanhas Comunitárias</span>
                    <span class="content">{{result.campanhas}}</span>
                    <span class="title">Pessoas alcançadas nas redes sociais</span>
                    <span class="content">{{result.alcance}}</span>
                </mat-card-content>
            </mat-card>
        </div>
    </section>
</section>