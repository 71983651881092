<section class="faq-list">
    <h2>Dúvidas gerais</h2>

    <section class="list">
        <mat-expansion-panel *ngFor="let faq of faqService.faqs$ | async">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    {{faq.pergunta}}
                </mat-panel-title>
            </mat-expansion-panel-header>
            <p [innerHTML]="faq.resposta"></p>
        </mat-expansion-panel>
    </section>
</section>