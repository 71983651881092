import { Component } from '@angular/core';

@Component({
  selector: 'app-people-and-co-hero',
  templateUrl: './people-and-co-hero.component.html',
  styleUrls: ['./people-and-co-hero.component.scss']
})
export class PeopleAndCoHeroComponent {

}
