<mat-dialog-content>
    <section class="left-container">
        <img class="background-image" [src]="data.foto">
        <p>
            <strong>{{data.nome}}</strong>
        </p>
    </section>
    <section class="right-container">
        <p>{{data.descricao}}</p>
    </section>

    <!-- <div [innerHTML]="data.conteudo"></div> -->
</mat-dialog-content>