export interface Partner{
    id?: string,
    nome: string,
    url_imagem: string,
    sobre: string,
    cnpj: number,
    categoria: Categoria,
    endereco: Endereco,
    link_doacao: string,
    email: string,
    telefone: string
}

export enum Categoria{

    NON_PROFIT = 'non_profit',
    CAMPANHA_COMUNITARIA = 'campanha_comunitaria',
    EMPRESA = 'empresa',
    PESSOA_FISICA = 'pessoa_fisisca',
    ESCOLA = 'escola',
    ORGAO_PUBLICO = 'orgao_publico'

}

export const LabelCategoria = {
    [Categoria.NON_PROFIT]: 'Organização sem fins lucrativos',
    [Categoria.CAMPANHA_COMUNITARIA]: 'Campanha Comunitária',
    [Categoria.EMPRESA]: 'Empresa',
    [Categoria.PESSOA_FISICA]: 'Pessoa física',
    [Categoria.ESCOLA]: 'Escola',
    [Categoria.ORGAO_PUBLICO]: 'Orgão Público',
}

export interface Endereco {
    /**
     * Address ZIP code.
     */
    cep: string;
    /**
     * Address line (street, avenue, etc.).
     */
    rua: string;
    /**
     * Address number.
     */
    numero: number;
    /**
     * Address additional information.
     */
    complemento: string;
    /**
     * Address neighborhood, region, county.
     */
    bairro: string;
    /**
     * Address city.
     */
    cidade: string;
    /**
     * Address state, province.
     */
    estado: string;
    /**
     * Latitude do endereço
     */
    latitude: number;
    /**
     * Longitude do endereço
     */
    longitude: number;
}


export interface PartnerForm{

}