import { Component } from '@angular/core';
import { ResultsService } from '../../services/results.service';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent {



  constructor(public resultsService: ResultsService){

  }

}
