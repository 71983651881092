<h2>Confira o time do Dia de Doar</h2>
<section class="ambassador-team">
    <div class="card" *ngFor="let ambassador of ambassadors">
        <mat-card (click)="openDialog(ambassador)">
            <mat-card-content>
                <img class="background-image" [src]="ambassador.foto">
                <img class="ler-mais" src="assets/icons/ler-mais.png" alt="picture">
            </mat-card-content>
        </mat-card>
        <span class="text">
            <strong>{{ambassador.nome}}</strong>
            <a href="https://www.linkedin.com/showcase/diadedoar/" target="_blank">
                <mat-icon class="icon material-icons color_white" svgIcon="linkedin-amarelo"></mat-icon>
            </a>
            <p>{{ambassador.cargo}}</p>
        </span>
    </div>
</section>