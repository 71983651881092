import { Component } from '@angular/core';

@Component({
  selector: 'app-how-it-started',
  templateUrl: './how-it-started.component.html',
  styleUrls: ['./how-it-started.component.scss']
})
export class HowItStartedComponent {

}
