<section class="donors">
    <section class="header">
        <h2>Nossos Doadores</h2>
        <!-- <a *ngIf="!description" mat-button class="round background-light-yellow ">Quer doar e apoiar a ampliação do Dia
            de Doar?</a> -->
    </section>
    <section class="list" *ngIf="donors">
        <div *ngFor="let categoria of keys" [ngClass]="categoria">
            {{categoria}}
            <div class="donor">
                <img *ngFor="let doador of donors[categoria]" [src]="doador.icone">
                <!-- <span>
                    {{doador.nome}}
                </span> -->
            </div>
        </div>
    </section>
    <section class="footer">
        <p>O Dia de Doar não intermedia as doações captadas pelas instituições participantes do
            movimento. Ou seja, nós não
            distribuímos recursos financeiros captados. Anualmente lançamos o Edital de Apoio à Filantropia Comunitária
            que
            seleciona coletivos e ativistas pela cultura de doação.</p>

        <ng-container *ngIf="description">
            <h3>Quer doar e apoiar a ampliação do Dia de Doar?</h3>
            <p>
                Nossos parceiros são pessoas físicas, empresas e organizações que ajudam a financiar este grande
                movimento nacional e
                executar estratégias para fortalecimento da cultura de doação no Brasil.
            </p>
            <p>Seja com doação direta de recursos ou prestando serviços que nos permitam alavancar a campanha
                nacionalmente, se sua
                empresa ou organização quer ser parceira do Dia de Doar, escreva para contato@diadedoar.org.br.</p>
        </ng-container>
    </section>

</section>
<!-- <mat-divider></mat-divider> -->