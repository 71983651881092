import { Component, Input } from '@angular/core';
import { ClockService } from '../../services/clock.service';
import { Timestamp } from 'firebase/firestore';

@Component({
  selector: 'app-countdown',
  templateUrl: './countdown.component.html',
  styleUrls: ['./countdown.component.scss']
})
export class CountdownComponent {
  now: Date = new Date();
  date: Date = new Date();

  constructor(private clockService: ClockService){
    this.clockService.getClock()
    this.clockService.localClock.subscribe(res=>{
      if(res) this.date = new Timestamp(res.data.seconds, res.data.nanoseconds).toDate()
    });
  }

  get timeDiff(){
    return this.date.getTime() - this.now.getTime();
  }

  get daysTo(){
    return Math.floor(this.timeDiff/(1000*60*60*24))
  }

  get hoursTo(){
    return Math.floor((this.timeDiff%(1000*60*60*24))/(1000*60*60))

  }

  get minutesTo(){
    return Math.floor((this.timeDiff%(1000*60*60))/(1000*60))
  }
}
