<section class="hero">
    <section class="title">
        <h1 class="text-box">
            <span class="green">
                Minha História
            </span>
            de Doação
        </h1>
        <p>Ao contar para o mundo sobre suas experiências de doação, você colabora na disseminação da cultura de doação
            e inspira
            outros a fazer o bem e compartilhar suas histórias.</p>
        <!-- <button class="round background-dark-blue" mat-button>Assista como funciona</button> -->
    </section>
    <section class="pictures">
        <img src="assets/hero/inspire-hero.png" alt="picture">
    </section>
</section>