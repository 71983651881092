<section class="about-intro">
    <mat-divider></mat-divider>
    <section class="title">
        <h3>Seja fazendo uma pessoa sorrir, ou doando para aqueles que precisam de atenção especial, todo mundo tem algo
            para doar.</h3>
        <p>Toda doação é importante para a construção de um mundo melhor.</p>
        <p>Na {{date | date:'fullDate'}}, o Brasil inteiro vai se mobilizar pelo Dia de Doar: milhares de
            organizações estarão preparadas para receber doações, e milhões de brasileiros vão demonstrar seu apoio,
            doando e
            tornando pública a doação, compartilhando a hashtag #diadedoar nas mídias sociais.</p>
    </section>
    <section class="pictures">
        <!-- <img src="https://t.ctcdn.com.br/gbO3hsV5DRUS3MYFIL0-vgDJtYk=/640x360/smart/i533291.png" alt="picture"> -->
        <iframe width="560" height="315" src="https://www.youtube.com/embed/qbTPvwNxFU0?si=x6QAkt3guRbPCkCG"
            title="YouTube video player" frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        <!-- https://www.youtube.com/watch?v=qbTPvwNxFU0 -->
    </section>
</section>