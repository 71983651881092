import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AmbassadorsComponent } from './ambassadors.component';
import { SharedModule } from '../shared/shared.module';
import { AmbassadorHeroComponent } from './components/ambassador-hero/ambassador-hero.component';
import { AmbassadorTeamComponent } from './components/ambassador-team/ambassador-team.component';
import { MatCardMdImage, MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { DiadeDoarTeamComponent } from './components/diade-doar-team/diade-doar-team.component';



@NgModule({
  declarations: [
    AmbassadorsComponent,
    AmbassadorHeroComponent,
    AmbassadorTeamComponent,
    DiadeDoarTeamComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    MatCardModule,
    MatIconModule
  ]
})
export class AmbassadorsModule { }
