import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NewsPost } from '../../models/posts.model';
import { Timestamp } from '@angular/fire/firestore';

@Component({
  selector: 'app-single-news',
  templateUrl: './single-news.component.html',
  styleUrls: ['./single-news.component.scss']
})
export class SingleNewsComponent implements OnInit{
  constructor(@Inject(MAT_DIALOG_DATA) public data: NewsPost, public dialogRef: MatDialogRef<SingleNewsComponent>) {}

  public timestampToDate(ts: Timestamp){
    return new Timestamp(ts.seconds, ts.nanoseconds).toDate()
  }

  ngOnInit(): void {
      this.dialogRef.updateSize('97%')
  }

}
