import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AboutComponent } from './about.component';
import { SharedModule } from '../shared/shared.module';
import { AboutHeroComponent } from './components/about-hero/about-hero.component';
import { AboutIntroComponent } from './components/about-intro/about-intro.component';
import { HowItStartedComponent } from './components/how-it-started/how-it-started.component';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';



@NgModule({
  declarations: [
    AboutComponent,
    AboutHeroComponent,
    AboutIntroComponent,
    HowItStartedComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    MatButtonModule,
    MatCardModule,
    MatButtonModule,
    MatDividerModule
  ]
})
export class AboutModule { }
