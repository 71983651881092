<section class="kids">
    <section class="title">
        <img src="assets/banners/banner_dia_kids.png" alt="picture">
    </section>
    <section class="pictures">
        <h2>Dia de Doar Kids</h2>
        <p>Em 2019 foi lançada a campanha Dia de Doar Kids, como parte do Dia de Doar, e que tem como proposta promover
            a gentileza
            e a generosidade junto ao público mais jovem. O Dia de Doar Kids faz parte da rede do #GivingTuesdaySpark. 
        </p>
        <p>
            <!-- <strong>
                Clique e conheça a Plataforma de Educação para Gentileza e Generosidade
            </strong> -->
        </p>
        <a mat-button class="round background-dark-blue" href="https://www.gentilezagenerosidade.org.br"
            target="_blank">Conheça
            a Plataforma</a>
    </section>
</section>