<section class="benefits">
    <section class="left-container">
        <img src="assets/photos/rondonia2.png" alt="">
        <img src="assets/photos/rondonia3.png" alt="">

        <!-- <button class="round background-light-blue" mat-button> Conhecer mais ></button> -->
    </section>
    <section class="right-container">
        <h2>Benefícios de liderar uma Campanha Comunitária do Dia de Doar</h2>
        <ul>
            <li>Construção de rede: a oportunidade de colaborar com uma rede de líderes como você para discutir
                desafios
                e oportunidades</li>
            <li>Coaching de construção de movimento: compartilharemos o aprendizado global da última década que fez
                do
                Dia de Doar um
                dos movimentos sociais de crescimento mais rápido</li>
            <li>Ferramentas e recursos para você compartilhar com sua rede e parceiros</li>
            <li>Facilite a aprendizagem entre pares: workshops e webinars para os membros de sua comunidade
                aprenderem
                com nossos
                construtores de movimento</li>
            <li>Conteúdo de marketing de causa: conteúdo para seu boletim informativo, blog e canais de mídia social
            </li>
            <li>Primeiro acesso às principais novidades do Dia de Doar </li>
            <li>Possibilidade de participar do Edital de Apoio à Campanhas Comunitárias do Dia de Doar </li>
            <li>Liderar, inspirar, mudar: a oportunidade de liderar um movimento que está transformando a sociedade
                por
                meio da
                generosidade radical e promovendo cultura de doação</li>
        </ul>
    </section>
</section>