<section class="what">
    <section class="left-container">
        <h2>O que faz um líder das Campanhas Comunitárias do Dia de Doar?</h2>
        <p>Criar e liderar uma Campanha Comunitária do Dia de Doar é gratificante e divertido. Abaixo estão as seis
            etapas básicas
            de planejamento para levar o Dia de Doar à sua comunidade.</p>
        <ul>
            <li>Planeja: desenvolva sua visão, metas e estratégia para fortalecer a cultura de doação em sua comunidade
            </li>
            <li>Anuncia: entre em contato com sua rede para que possam divulgar juntos o movimento do Dia de Doar e
                maneiras de se
                envolver</li>
            <li>Recruta participantes: reúna seus amigos e colegas, entre em contato com sua rede de ex-alunos de
                faculdade/escola,
                empresas locais, organizações sem fins lucrativos e lojas de varejo</li>
            <li>Espalha a palavra: use uma variedade de táticas de mídia social, divulgação direcionada, compra de
                anúncios, mídia
                tradicional e parcerias para aumentar a conscientização</li>
            <li>Organiza eventos: para engajar novos doadores, é essencial que ele conheça seu trabalho e a
                possibilidade de se tornar
                doador. Use este período para deixar isso bem claro!
            </li>
            <li>Aprende: compartilhe suas informações, acompanhe os resultados de outras campanhas e analise os seus
                resultados.
                Aprenda com outros líderes sobre o que funciona para engajar mais doadores. </li>
        </ul>
        <p>Nossa equipe está disposta para apoiar ao longo do caminho, ajudar a planejar seu movimento e para conectá-lo
            com outros
            líderes do Dia de Doar.</p>

        <!-- <button class="round background-light-blue" mat-button> Conhecer mais ></button> -->
    </section>
    <section class="right-container">
        <img src="assets/photos/rondonia4.png" alt="">
    </section>
</section>