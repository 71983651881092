<section class="simple">
    <h2>Maneiras simples de espalhar amor e boas ações nas redes sociais</h2>

    <section class="cards">
        <mat-card>
            <mat-card-content>
                <h2>1</h2>
                <p>Publique uma declaração do porquê você é doador</p>
                <a href="https://www.canva.com/design/DAFqcELFyLE/3X-xj6O7JtlnUL66jdV6xg/edit?utm_content=DAFqcELFyLE&utm_campaign=designshare&utm_medium=link2&utm_source=sharebutton"
                    target="_blank" mat-button class="round background-light-blue">Baixar modelo</a>
                <!-- <img src="assets/mascotes/como-comecou.png" alt=""> -->
            </mat-card-content>
        </mat-card>
        <mat-card>
            <mat-card-content>
                <h2>2</h2>
                <p>Adicione uma moldura ao seu perfil do Linkedin para mostrar que você está doando no #DiaDeDoar</p>
                <a href="https://www.canva.com/design/DAFsfBkGw3w/FrAx5ZTrtJW7FEkFklatzg/edit?utm_content=DAFsfBkGw3w&utm_campaign=designshare&utm_medium=link2&utm_source=sharebutton"
                    target="_blank" class="round background-light-blue">
                    <!-- <mat-icon>download</mat-icon> -->
                    Baixar modelo</a>
            </mat-card-content>
        </mat-card>
        <mat-card>
            <mat-card-content>
                <h2>3</h2>
                <p>Faça uma doação e compartilhe com a <strong>#DiadeDoar</strong></p>
                <span></span>
            </mat-card-content>
        </mat-card>
    </section>

    <section class="you-can">
        <mat-card class="background-light-blue">
            <mat-card-content class="content">
                <h3>Você também pode:</h3>
                <ul>
                    <li>Criar sua própria mobilização para uma organização sem fins lucrativos</li>
                    <li>Adicionar um GIF do Dia de Doar nos seus stories do Instagram. Pesquise por “diadedoar”</li>
                    <li>Compartilhar uma causa importante para você ou uma história sobre como alguém o inspirou a
                        ajudar
                        com a #diadedoar</li>
                    <li>Adicionar o link da sua organização sem fins lucrativos favorita à sua assinatura de e-mail
                        profissional ou pessoal</li>
                </ul>
            </mat-card-content>
        </mat-card>
    </section>

</section>