<section class="blog-news">
    <h1>Notícias</h1>
    <ng-container *ngIf="newsService.posts$ | async as posts">
        <section class="cards">
            <ng-container *ngFor="let post of posts; let i = index">
                <mat-card><mat-card-header *ngIf="post.banner">
                        <img class="card-image" [src]="post.banner" alt="">
                        <mat-chip disabled *ngFor="let category in post.categorias">{{NewsCategoryLabel[category] ||
                            category}}</mat-chip>
                    </mat-card-header>
                    <!-- <mat-card-header>
                                <img class="card-image"
                                    src="https://blog.iprocess.com.br/wp-content/uploads/2021/11/placeholder.png" alt="">
                            </mat-card-header> -->
                    <mat-card-content>
                        <span class="content">
                            <p>{{timestampToDate(post.data) | date:'fullDate'}}</p>
                            <h3>{{post.titulo}}</h3>
                            <ng-container *ngIf="!post.banner">
                                <!-- <div [innerHTML]="post.conteudo"></div> -->
                            </ng-container>

                        </span>
                    </mat-card-content>
                    <mat-card-footer>
                        <a class="round" [routerLink]="'/noticia/'+btoa(post.id)">Ler mais</a>

                    </mat-card-footer>
                </mat-card>
            </ng-container>
        </section>
        <button mat-icon-button color="primary" [disabled]="posts.length >= newsService.postsCount"
            (click)="loadMorePosts()">
            <mat-icon>add</mat-icon>
        </button>
    </ng-container>

</section>