import { Component } from '@angular/core';
import { BaseHeroService } from 'src/app/shared/services/base-hero.service';

@Component({
  selector: 'app-about-hero',
  templateUrl: './about-hero.component.html',
  styleUrls: ['./about-hero.component.scss']
})
export class AboutHeroComponent extends BaseHeroService {

}
