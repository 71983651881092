import { Component } from '@angular/core';

@Component({
  selector: 'app-blog-hero',
  templateUrl: './blog-hero.component.html',
  styleUrls: ['./blog-hero.component.scss']
})
export class BlogHeroComponent {

}
