import { Injectable } from '@angular/core';
import { Firestore } from '@angular/fire/firestore';
import { BehaviorSubject } from 'rxjs';
import { BaseCollectionService } from '../collections/base-collection.service';
import { Deposition } from '../models/deposition.model';

@Injectable({
  providedIn: 'root'
})
export class DepositionsService extends BaseCollectionService<Deposition>{

  protected override collectionName: string = 'depoimentos';

  protected override sortObj = {
    active: "nome",
    direction:"asc"
  }

  protected override limit = 20;

  private lastDepositionLoaded:any = null;
  public DepositionsCount = 0;

  private _Depositions$ = new BehaviorSubject<Deposition[]>([]);
  public Depositions$ = this._Depositions$.asObservable();

  constructor(protected override fireStore:Firestore) {
    super(fireStore);
    this.getDepositionsCount();
    this.getDepositions();
  }

  private async getDepositionsCount(){
    this.DepositionsCount = await this.docCount();
  }

  public async createDeposition(Deposition:Deposition){
    this.createDoc(Deposition).then(()=>this.resetLocal())
  }

  public async editDeposition(Deposition:Deposition, id:string){
    this.editDoc(Deposition, id).then(()=>this.resetLocal())
  }

  public async getDepositions(searchString: string | null = null){
    if(searchString){
      this._Depositions$.next([]);
      this.lastDepositionLoaded = null;
    }
    const docs = (await this.getDocs(this.lastDepositionLoaded, searchString)).docs;
    if(docs.length > 0){
      this.lastDepositionLoaded = docs[docs.length-1];
      const resultsArr = docs.map(val=>Object.assign({} , {...val.data(), id: val.id} as Deposition));
      this._Depositions$.next([...this._Depositions$.value, ...resultsArr]);
    }
  }

  clearSearch(){
    this._Depositions$.next([]);
    this.lastDepositionLoaded = null;
    this.getDepositions();
  }

  public async excludeDeposition(id:string){
    await this.deleteDoc(id).then(()=>this.resetLocal());
  }

  private resetLocal(){
      this.lastDepositionLoaded = null;
      this._Depositions$.next([]);
      this.getDepositions();
      this.getDepositionsCount();
  }
}
