<section class="impact">
    <h1>Impactos do Dia de Doar</h1>
    <p>Somos um movimento global que potencializa o poder das pessoas, organizações e comunidades na promoção de um
        mundo mais
        doador.</p>
    <section class="cards" *ngIf="dashboardService.dashboard | async as dashboard">
        <ng-container *ngFor="let resultado of dashboard.resultados; let i=index">
            <mat-card *ngIf="i<4">
                <mat-card-content>
                    <div class="card-value">{{resultado.titulo}}</div>
                    <div class="card-title">{{resultado.descricao}}</div>
                </mat-card-content>
            </mat-card>
        </ng-container>
    </section>

    <button class="round background-light-yellow" mat-button [routerLink]="'/dados'">Nosso programa de dados</button>
</section>