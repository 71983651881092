<app-campaign-hero></app-campaign-hero>
<app-comunity-campaign></app-comunity-campaign>
<app-officialized-cities></app-officialized-cities>
<app-comunity-lead></app-comunity-lead>
<app-comunity-benefits></app-comunity-benefits>
<app-comunity-what-makes></app-comunity-what-makes>
<app-category-news></app-category-news>
<!-- <app-leader></app-leader> -->
<app-common-questions></app-common-questions>
<app-depositions></app-depositions>
<app-donors></app-donors>