import { Component } from '@angular/core';

@Component({
  selector: 'app-mobilize',
  templateUrl: './mobilize.component.html',
  styleUrls: ['./mobilize.component.scss']
})
export class MobilizeComponent {

}
