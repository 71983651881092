import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-day-preview',
  templateUrl: './day-preview.component.html',
  styleUrls: ['./day-preview.component.scss']
})
export class DayPreviewComponent {

  @Input() date!: Date

  get day(){
    return this.date.getDate();
  }

  get month(){
    return this.date.toLocaleString('default', { month: 'long' }).substring(0, 3);
  }

}
