<section class="hero">
    <section class="title">
        <h1 class="text-box">
            Convide
            <span class="blue">todo mundo</span>
            para participar!
        </h1>
        <p>
            Junte-se ao movimento dedicado a impulsionar a generosidade no Brasil. Apoie suas causas do coração no Dia
            de Doar
            envolvendo seus amigos e familiares.
        </p>
        <a class="round background-dark-blue" mat-button href="https://www.youtube.com/watch?v=qbTPvwNxFU0 "
            target="_blank">Assista ao video</a>
    </section>
    <section class="pictures">
        <img src="assets/banners/banner_pessoas_familia_amigos.png" alt="picture">
    </section>
</section>