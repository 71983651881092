import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeroComponent } from './components/hero/hero.component';
import { HomeComponent } from './home.component';
import { SharedModule } from '../shared/shared.module';
import { MatButtonModule } from '@angular/material/button';
import { PresentationComponent } from './components/presentation/presentation.component';
import { RouterModule } from '@angular/router';



@NgModule({
  declarations: [
    HeroComponent,
    HomeComponent,
    PresentationComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    MatButtonModule,
    RouterModule
  ]
})
export class HomeModule { }
