<section class="hero">
    <section class="title">
        <h1 class="text-box">
            Nosso Blog
        </h1>
        <p>O Dia de Doar é o maior movimento de incentivo à generosidade do mundo. Saiba o que temos feito para
            conscientizar a população brasileira.</p>
        <!-- <button class="round background-dark-blue" mat-button>Assista como funciona</button> -->
    </section>
    <section class="pictures">
        <img src="assets/hero/blog-hero.png" alt="picture">
    </section>
</section>