import { Component } from '@angular/core';

@Component({
  selector: 'app-comunity-campaign',
  templateUrl: './comunity-campaign.component.html',
  styleUrls: ['./comunity-campaign.component.scss']
})
export class ComunityCampaignComponent {

}
