<section class="whatsapp">

    <section class="text">
        <h2>Participe dos nossos grupos de

            <strong>Whatsapp</strong>
        </h2>
        <a class="round background-green" mat-button href="https://chat.whatsapp.com/BmlPkGe13oxKAne1zM30sI"
            target="_blank">
            <mat-icon class="icon" svgIcon="whatsapp"></mat-icon>
            Clique aqui
        </a>
    </section>
    <section class="image">
        <img src="assets/mascotes/personagemVerde.png" alt="">
    </section>
</section>