import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResultsComponent } from './results.component';
import { SharedModule } from '../shared/shared.module';
import { ResultsHeroComponent } from './components/results-hero/results-hero.component';



@NgModule({
  declarations: [
    ResultsComponent,
    ResultsHeroComponent
  ],
  imports: [
    CommonModule,
    SharedModule
  ]
})
export class ResultsModule { }
