<section class="hero">
    <section class="title">
        <h1 class="text-box">
            Conheça as ferramentas para promover o Dia de Doar na sua organização!
            <!-- <span class="green">
                cultura de doação
            </span>
            no Brasil. -->
        </h1>
        <p>
            Existem muitas maneiras de usar seu poder para impulsionar a generosidade e fazer a diferença em sua
            comunidade no dia
            <span class="date">{{date | date:'d \'de\' MMMM, y'}}</span>, #DiaDeDoar! 
        </p>
        <a mat-button class="round background-light-blue " href="https://www.givingtuesday.ca/">Assista o video ></a>
        <p>E nós realmente acreditamos que juntos podemos fazer muita diferença.</p>
        <p>E se a gente promover juntos uma "BlackFriday das OSCs", em que organizações de norte a sul do Brail oferecem
            promoções e novas oportunidade para a participação da sociedade nas nossas atividades?</p>
        <p>Este kit de ferramentas contém tudo o que você precisa para participar do movimento global de generosidade
        </p>
        <!-- <button mat-button>Assista como funciona</button> -->
    </section>
    <section class="pictures">
        <img src="assets/banners/banner_para_ONGs.png" alt="picture">
    </section>
</section>