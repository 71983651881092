import { Component } from '@angular/core';

@Component({
  selector: 'app-inspire',
  templateUrl: './inspire.component.html',
  styleUrls: ['./inspire.component.scss']
})
export class InspireComponent {

}
