import { Component } from '@angular/core';

@Component({
  selector: 'app-store-button',
  templateUrl: './store-button.component.html',
  styleUrls: ['./store-button.component.scss']
})
export class StoreButtonComponent {

}
