<section class="detail">

    <!-- <section class="image">
        <img src="assets/mascotes/personagemAmarelo.png" alt="">
    </section> -->
    <section class="text">
        <h2>{{text}}</h2>
        <p *ngIf="subtitle">{{subtitle}}</p>
        <!-- <p>Assine nossa Newsletter!</p> -->
    </section>
    <section class="buttons">
        <!-- <h2>{{text}}</h2> -->
        <button *ngIf="route" mat-button class="round" [routerLink]="[route]"
            routerLinkActive="router-link-active">{{label}}</button>
        <a *ngIf="link" mat-button class="round" [href]="link" target="_blank">{{label}}</a>
    </section>
</section>