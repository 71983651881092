import { Component } from '@angular/core';

@Component({
  selector: 'app-arrived-now',
  templateUrl: './arrived-now.component.html',
  styleUrls: ['./arrived-now.component.scss']
})
export class ArrivedNowComponent {

}
