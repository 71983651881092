import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { PeopleAndCoHeroComponent } from './components/people-and-co-hero/people-and-co-hero.component';
import { PeopleAndCoComponent } from './people-and-co.component';
import { BecomeADonorComponent } from './components/become-adonor/become-adonor.component';
import { MatCardModule } from '@angular/material/card';
import { SimpleWaysComponent } from './components/simple-ways/simple-ways.component';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';



@NgModule({
  declarations: [
    PeopleAndCoComponent,
    PeopleAndCoHeroComponent,
    BecomeADonorComponent,
    SimpleWaysComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    MatCardModule,
    MatIconModule,
    RouterModule
  ]
})
export class PeopleAndCoModule { }
