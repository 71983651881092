import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PageItem } from '../layout.component';
import { Timestamp } from '@angular/fire/firestore';
import { ClockService } from 'src/app/shared/services/clock.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {

  @Input() pages!: PageItem[];

  @Output() toggleMenu = new EventEmitter();

  constructor(private clockService: ClockService){
    this.clockService.getClock()
    this.clockService.localClock.subscribe(res=>{
      if(res) this.dDay = new Timestamp(res?.data?.seconds, res?.data?.nanoseconds).toDate()
    });
  }

  public dDay!: Date;

}
