import { Component } from '@angular/core';

@Component({
  selector: 'app-press-hero',
  templateUrl: './press-hero.component.html',
  styleUrls: ['./press-hero.component.scss']
})
export class PressHeroComponent {

}
