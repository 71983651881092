<section class="lead">
    <section class="left-container">
        <h2>Quem pode liderar uma Campanha Comunitária do Dia de Doar?</h2>
        <p>
            Campanhas Comunitárias do Dia de Doar são formadas quando pessoas de uma mesma região, causa ou identidade
            se reúnem
            para fazer a diferença na promoção da cultura de doação e na conexão entre pessoas e suas causas, de dentro
            das próprias
            comunidades. 
        </p>
        <p>Eles encontram maneiras criativas de mobilizar redes, organizar eventos voluntários, conscientizar sobre
            causas e
            arrecadar fundos para organizações sem fins lucrativos locais – gerando ondas de generosidade e inspirando
            mudanças.</p>
        <p>Os líderes comunitários do Dia de Doar envolvem parceiros em todos os setores da sociedade (empresas, poder
            público,
            escolas, igrejas, famílias, etc) para inspirar toda a comunidade a usar seu poder de generosidade radical
            para construir
            o mundo em que todos queremos viver.</p>
        <!-- <button class="round background-light-blue" mat-button> Conhecer mais ></button> -->
    </section>
    <section class="right-container">
        <img src="assets/photos/rondonia.png" alt="">
    </section>
</section>