<section class="presentation">
    <h3>Doar precisa virar assunto do dia a dia, conversa na hora do jantar</h3>
    <p>Segundo a Diretriz 2 do Movimento por uma Cultura de Doação, “a promoção de uma cultura de doação precisa de uma
        narrativa mais engajadora, positiva, qualificada, inclusiva e que chegue a uma diversidade maior de públicos.
        Que
        conecte com a realidade das pessoas, crie empatia com causas e promova a confiança no poder transformador de uma
        doação,
        mudando paradigmas. Nesta nova narrativa, a doação será compreendida como ato de cidadania e fortalecimento da
        democracia.”</p>

    <a class="round background-light-yellow" href="https://forms.gle/VAZeAKjsGLdUxJ7J6" target="_blank" mat-button>Envie
        sua história ></a>
</section>