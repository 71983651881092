import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-deposition-card',
  templateUrl: './deposition-card.component.html',
  styleUrls: ['./deposition-card.component.scss']
})
export class DepositionCardComponent {
  @Input() id!: string;
  @Input() texto!: string;
  @Input() nome!: string;

}
