import { Component } from '@angular/core';

@Component({
  selector: 'app-no-profit',
  templateUrl: './no-profit.component.html',
  styleUrls: ['./no-profit.component.scss']
})
export class NoProfitComponent {

}
