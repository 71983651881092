<section class="hero">
    <section class="title">
        <h1 class="text-box">
            Fortaleça a cultura
            de doação da sua comunidade!
        </h1>
        <p>Existem muitas maneiras de usar seu poder para impulsionar a generosidade e fazer a diferença em sua
            comunidade no dia <span class="date">{{date | date:'d \'de\' MMMM, y'}}</span>, #DiaDeDoar! </p>
        <p>E nós realmente acreditamos que juntos podemos fazer muita diferença.</p>
        <a class="round background-dark-blue" mat-button>Assista o vídeo</a>
    </section>
    <section class="pictures">
        <img src="assets/banners/banner_campanhas_comunitarias.png" alt="picture">
    </section>
</section>