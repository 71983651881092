<section class="partners">
    <section class="left">

        <h2>Parceiros de dados</h2>
        <p>
            Para entender melhor o impacto dos milhares de atos de generosidade que são resultados do Dia de Doar,
            contamos com
            parcerias das empresas que processam doações online, plataformas de mídias sociais, etc.
        </p>
        <p>
            Abaixo estão as empresas que são parceiras do Programa de Dados em 2023. Sua empresa processa dados sobre
            doações e você
            quer contribuir também? Então fale com a gente! Escreva para contato@diadedoar.org.br e converse com nossa
            equipe.
        </p>
    </section>
    <section class="right">
        <h3>
            Parceria de Dados em 2023
        </h3>
        <mat-card>
            <mat-card-content>
                <img src="assets/footer/image 4.png" alt="picture">
                <img src="assets/footer/image 5.png" alt="picture">
            </mat-card-content>
        </mat-card>
    </section>
</section>