import { Component } from '@angular/core';

@Component({
  selector: 'app-become-adonor',
  templateUrl: './become-adonor.component.html',
  styleUrls: ['./become-adonor.component.scss']
})
export class BecomeADonorComponent {

}
