import { Component } from '@angular/core';
import { MainNewsService } from '../services/main-news.service';
import { NewsService } from '../services/news.service';
import { Timestamp } from '@angular/fire/firestore';
import { MatDialog } from '@angular/material/dialog';
import { SingleNewsComponent } from '../components/single-news/single-news.component';
import { NewsPost } from '../models/posts.model';

@Component({
  selector: 'app-other-histories',
  templateUrl: './other-histories.component.html',
  styleUrls: ['./other-histories.component.scss']
})
export class OtherHistoriesComponent {

  constructor(public newsService: MainNewsService, public dialog: MatDialog){
    this.newsService.filterField = "categorias"
    this.newsService.filterValue = "Minha História de Doação"
    this.newsService.resetLocal();
    // this.newsService.getPosts();
  }

  public timestampToDate(ts: Timestamp){
    return new Timestamp(ts.seconds, ts.nanoseconds).toDate()
  }

  openDialog(post:NewsPost){
    this.dialog.open(SingleNewsComponent,
      {
      width: '95vw',
      maxWidth:'100vw',
      data: post,
    });
  }

}
