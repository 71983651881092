import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Embaixador } from '../../models/dashboard.model';

@Component({
  selector: 'app-show-ambassador',
  templateUrl: './show-ambassador.component.html',
  styleUrls: ['./show-ambassador.component.scss']
})
export class ShowAmbassadorComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: Embaixador, public dialogRef: MatDialogRef<ShowAmbassadorComponent>) {}

}
