<section class="arrived-now">
    <h1>Chegou agora?</h1>
    <p>Aqui estão alguns recursos que podem te ajudar a começar sua campanha!</p>
    <section class="cards">
        <mat-card class="mat-elevation-z0">
            <mat-card-content>
                <!-- <img src="assets/mascotes/PersonagemCiano.png" alt="picture"> -->
                <mat-icon class="blue">handyman</mat-icon>
                <h2>Ferramentas</h2>
                <mat-list>
                    <a href="https://drive.google.com/file/d/1PZOJwaQlDuRyAc4olspMHUu-tdvhSvr7/view?usp=sharing"
                        target="_blank">
                        <mat-list-item class="no-wrap">
                            Guia de campanhas
                            <span matListItemMeta>
                                <mat-icon class="blue">chevron_right</mat-icon>
                            </span>
                        </mat-list-item>
                    </a>
                    <a href="https://docs.google.com/spreadsheets/d/1hWgiZoYoPqzgrvSOXbpAdvKz92ajP2Qj/edit?usp=sharing&ouid=117988431013441739014&rtpof=true&sd=true"
                        target="_blank">
                        <mat-list-item>Planejamento de redes sociais<span matListItemMeta>
                                <mat-icon class="blue">chevron_right</mat-icon>
                            </span>
                        </mat-list-item>
                    </a>
                    <a href="https://drive.google.com/drive/folders/17VrP_FoSEWIivVGuCBMxluHeNjVvmeS3?usp=sharing"
                        target="_blank">
                        <mat-list-item>Manuais de participação<span matListItemMeta>
                                <mat-icon class="blue">chevron_right</mat-icon>
                            </span>
                        </mat-list-item>
                    </a>
                </mat-list>
            </mat-card-content>
        </mat-card>
        <mat-card class="mat-elevation-z0">
            <mat-card-content><!-- <img src="assets/mascotes/PersonagemCiano.png" alt="picture"> -->
                <mat-icon class="green">light</mat-icon>
                <h2>Inspire-se</h2>
                <mat-list>
                    <a [routerLink]="['/inspire-se']">
                        <mat-list-item>
                            Minha História de Doação
                            <span matListItemMeta>
                                <mat-icon class="green">chevron_right</mat-icon>
                            </span>
                        </mat-list-item>
                    </a>
                    <a [routerLink]="['/blog']">
                        <mat-list-item>Conheça cases reais<span matListItemMeta>
                                <mat-icon class="green">chevron_right</mat-icon>
                            </span>
                        </mat-list-item>
                    </a>
                    <a href="https://www.youtube.com/@DiadeDoarBrasil/streams" target="_blank">
                        <mat-list-item>Webinars<span matListItemMeta>
                                <mat-icon class="green">chevron_right</mat-icon>
                            </span>
                        </mat-list-item>
                    </a>
                    <a href="https://instagram.com/diadedoar" target="_blank">
                        <mat-list-item>Siga nossas redes sociais<span matListItemMeta>
                                <mat-icon class="green">chevron_right</mat-icon>
                            </span>
                        </mat-list-item>
                    </a>
                </mat-list>

            </mat-card-content>
        </mat-card>
        <mat-card class="mat-elevation-z0">
            <mat-card-content><!-- <img src="assets/mascotes/PersonagemCiano.png" alt="picture"> -->
                <mat-icon class="yellow">photo_library</mat-icon>
                <h2>Materiais gráficos</h2>
                <mat-list>
                    <a href="https://relacionamento.captadores.org.br/ddd-lp-perene-ddd24-download-de-materiais"
                        target="_blank">

                        <mat-list-item>
                            Logos
                            <span matListItemMeta>
                                <mat-icon class="yellow">chevron_right</mat-icon>
                            </span>
                        </mat-list-item>
                    </a>
                    <a href="https://relacionamento.captadores.org.br/ddd-lp-perene-ddd24-download-de-materiais"
                        target="_blank">
                        <mat-list-item>Personagens<span matListItemMeta>
                                <mat-icon class="yellow">chevron_right</mat-icon>
                            </span>
                        </mat-list-item>
                    </a>
                    <a href="https://relacionamento.captadores.org.br/ddd-lp-perene-ddd24-download-de-materiais"
                        target="_blank">

                        <mat-list-item>Stickers<span matListItemMeta>
                                <mat-icon class="yellow">chevron_right</mat-icon>
                            </span>
                        </mat-list-item>
                    </a>
                    <a href="https://relacionamento.captadores.org.br/ddd-lp-perene-ddd24-download-de-materiais"
                        target="_blank">
                        <mat-list-item class="no-wrap">Todos os materiais<span matListItemMeta>
                                <mat-icon class="yellow">chevron_right</mat-icon>
                            </span>
                        </mat-list-item>
                    </a>
                </mat-list>
            </mat-card-content>
        </mat-card>
    </section>
</section>