<section class="hero">
    <section class="title">
        <h1 class="text-box">
            O maior movimento de filantropia comunitária do Brasil
        </h1>
        <p>
            O Brasil foi um dos 5 primeiros países do mundo a representar o GivingTuesday fora dos EUA. Desde 2013 temos
            um grande
            objetivo: tornar a doação mais central na vida diária. Nosso compromisso com um conjunto básico de valores,
            um modelo de
            liderança aberto e distribuído e uma crença em parcerias transformacionais nos colocam no caminho para
            construir o
            primeiro movimento global de generosidade e o primeiro dia global de celebração aberto a todos e a todas.
        </p>
        <p>
            Hoje, o Dia de Doar é uma comunidade inclusiva e pluralista de milhões de doadores, com atividades em todas
            as regiões
            do Brasil. Celebramos e exaltamos a generosidade popular e a generosidade como um valor universalmente
            aceito.
        </p>
        <p>
            Em diversas culturas, nossa rede de líderes está presente em mais de 90 países e centenas de comunidades
            inovando,
            colaborando e inspirando a generosidade coletiva como forma de construir o mundo em que desejamos viver,
            todos juntos.
        </p>
        <p>Ao olharmos para o nosso décimo aniversário e mirarmos nos próximos dez anos, somos energizados pela
            oportunidade de
            criar um futuro melhor juntos e abordamos esse trabalho com esperança, ambição e humildade.</p>
    </section>
    <section class="pictures">
        <img src="assets/banners/banner_resultados.png" alt="picture">
    </section>
</section>