import { Component, Input, OnInit, inject } from '@angular/core';
import { CategoryNewsService, NewsCategoryLabel } from '../../services/category-news.service';
import { Router } from '@angular/router';
import { Timestamp } from '@angular/fire/firestore';
import { MatDialog } from '@angular/material/dialog';
import { NewsPost } from '../../models/posts.model';
import { SingleNewsComponent } from '../single-news/single-news.component';

@Component({
  selector: 'app-category-news',
  templateUrl: './category-news.component.html',
  styleUrls: ['./category-news.component.scss']
})
export class CategoryNewsComponent{

  public NewsCategoryLabel = NewsCategoryLabel;


  constructor(private router:Router, public dialog: MatDialog, public categoryNewsService: CategoryNewsService){

    this.categoryNewsService.filterValue = this.router.url.substring(1);
    // console.log(this.router.url.substring(1))
    // this.categoryNewsService.resetLocal();
    this.categoryNewsService.getPosts();
  }

  public timestampToDate(ts: Timestamp){
    return new Timestamp(ts.seconds, ts.nanoseconds).toDate()
  }

  openDialog(post:NewsPost){
    this.dialog.open(SingleNewsComponent,
      {
      width: '95vw',
      maxWidth:'100vw',
      data: post,
    });
  }


  btoa(string: any){
    return btoa(String(string));
  }

}
