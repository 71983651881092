import { Component } from '@angular/core';

@Component({
  selector: 'app-press-presentation',
  templateUrl: './press-presentation.component.html',
  styleUrls: ['./press-presentation.component.scss']
})
export class PressPresentationComponent {

}
