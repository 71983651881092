import { Component } from '@angular/core';
import { DashboardService } from '../../services/dashboard.service';

@Component({
  selector: 'app-principal-results',
  templateUrl: './principal-results.component.html',
  styleUrls: ['./principal-results.component.scss']
})
export class PrincipalResultsComponent {
  constructor(public dashboardService:DashboardService){}
}
