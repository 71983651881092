<section class="how-it-started">
    <section class="title">
        <h2>Como começou</h2>
        <p>No Brasil, o Dia de Doar foi realizado pela primeira vez em 2013, um ano depois da primeira edição, nos
            Estados Unidos,
            em 2012. A partir de 2014 o Brasil passou a fazer parte do movimento global, que hoje conta com mais de 90
            países
            participando oficialmente.</p>
        <a mat-button class="round background-light-yellow " href=" https://www.givingtuesday.org/global/"
            target="_blank">Conheça os países
            participantes ></a>
        <p>Lá fora, o Dia de Doar tem nome de #GivingTuesday, que significa "terça-feira da doação". Vem na sequência de
            datas
            comerciais já famosas, como as BlackFriday e CyberMonday. É sempre realizado na primeira
            <strong>
                terça-feira depois do Dia de
                Ação de Graças
            </strong>
            (o Thanksgiving Day).
        </p>
        <p>Quem organiza e lidera a mobilização é a

            <a href="https://captadores.org.br/">ABCR - Associação Brasileira de Captadores de Recursos</a>
            . E o Dia de
            Doar é
            realizado como parte do
            <a href="https://www.doar.org.br/">Movimento por uma Cultura de Doação,</a>
            uma coalização de organizações e indivíduos que
            promovem o
            engajamento das pessoas com as causas e as organizações da sociedade civil, por meio da doação como
            instrumento para
            fortalecimento da democracia.
        </p>
    </section>
    <section class="pictures">
        <mat-card>
            <mat-card-content>
                <h4>E se a gente se juntasse pra fazer uma BlackFriday das ONGs?</h4>
                <p>Veja como promover ações promocionais do Dia de Doar na Black Friday</p>
                <img src="assets/mascotes/como-comecou.png" alt="">
            </mat-card-content>
            <mat-card-footer>
                <button mat-button class="round background-dark-blue">Confira
                    ></button>

            </mat-card-footer>
        </mat-card>
    </section>
</section>