import { Component } from '@angular/core';

@Component({
  selector: 'app-faq-hero',
  templateUrl: './faq-hero.component.html',
  styleUrls: ['./faq-hero.component.scss']
})
export class FaqHeroComponent {

}
