<mat-divider></mat-divider>
<section class="footer">
    <div class="footer-left">
        <section class="pages">
            <div *ngFor="let page of pages">
                <p *ngIf="!page.link">
                    <strong>
                        {{page.name}}
                    </strong>
                </p>
                <a *ngIf="page.link" [routerLink]="[page.link]">
                    {{page.name}}
                </a>
                <ng-container *ngFor="let item of page.itens">
                    <a [routerLink]="[item.link]">
                        {{item.name}}
                    </a>
                </ng-container>
            </div>
        </section>
        <mat-divider></mat-divider>
        <section class="social">
            <a [routerLink]="['/home']">
                <mat-icon class="icon" svgIcon="header-icon"></mat-icon>
            </a>
            <a href="https://www.doar.org.br/" target="_blank">
                <img src="assets/footer/logoMCDAmarelo.png">
            </a>
            <a href="https://captadores.org.br/" target="_blank">
                <img src="assets/footer/logoABCR.png">
            </a>
            <div class="links">
                <a href="https://instagram.com/diadedoar/" target="_blank">
                    <mat-icon class="icon" svgIcon="instagram"></mat-icon>
                </a>
                <a href="https://www.linkedin.com/showcase/diadedoar/" target="_blank">
                    <mat-icon class="icon" svgIcon="linkedin"></mat-icon>
                </a>
                <a href="https://youtube.com/diadedoarbrasil" target="_blank">
                    <mat-icon class="icon" svgIcon="youtube"></mat-icon>
                </a>
                <a href="https://twitter.com/diadedoar" target="_blank">
                    <mat-icon class="icon" svgIcon="twitter"></mat-icon>
                </a>
                <a href="https://facebook.com/diadedoar/" target="_blank">
                    <mat-icon class="icon" svgIcon="facebook"></mat-icon>
                </a>
            </div>
        </section>
    </div>
    <div class="footer-right">
        <h2>Ficou com dúvidas?</h2>
        <p>
            Visite nosso FAQ para perguntas frequentes. Caso queira entrar em contato com a nossa equipe, envie um
            e-mail para
            <a href="mailto: contato@diadedoar.org.br">contato@diadedoar.org.br</a>
            e retornaremos assim que possível.
        </p>
        <form class="form" [formGroup]="form">
            <mat-form-field MatFormFieldControl="nome">
                <mat-label>Nome</mat-label>
                <input matInput>
            </mat-form-field>
            <mat-form-field MatFormFieldControl="email">
                <mat-label>Email</mat-label>
                <input matInput>
            </mat-form-field>
            <mat-form-field MatFormFieldControl="mensagem">
                <mat-label>Mensagem</mat-label>
                <textarea matInput></textarea>
            </mat-form-field>
            <button (click)="onSubmit()" class="round background-light-blue" type="submit" mat-button>Enviar</button>
        </form>
    </div>
    <div class="footer-mobile">
        <section class="social">
            <mat-icon class="icon" svgIcon="header-icon"></mat-icon>
            <a href="https://www.doar.org.br/" target="_blank">
                <img src="assets/footer/logoMCDAmarelo.png">
            </a>
            <a href="https://captadores.org.br/" target="_blank">
                <img src="assets/footer/logoABCR.png">
            </a>
            <div class="pages">
                <app-sidenav-list [pages]="pages"></app-sidenav-list>
            </div>
            <div class="links">
                <mat-icon class="icon" svgIcon="instagram"></mat-icon>
                <mat-icon class="icon" svgIcon="linkedin"></mat-icon>
                <mat-icon class="icon" svgIcon="youtube"></mat-icon>
                <mat-icon class="icon" svgIcon="twitter"></mat-icon>
                <mat-icon class="icon" svgIcon="facebook"></mat-icon>
            </div>
        </section>

    </div>
</section>