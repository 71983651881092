<section class="ideas">
    <section class="title">

        <h2>Aqui estão algumas ideias, mas as possibilidades
            são absolutamente infinitas:</h2>
        <!-- <a mat-button class="round background-dark-blue">Confira mais ideias do que você pode fazer
            ></a> -->
    </section>
    <section class="cards">
        <mat-card>
            <mat-card-content>
                <p>Forme um comitê ou coalizão de organizações, micro e pequenas empresas, e outros líderes comunitários
                    para que construam
                    juntos uma mobilização pelo Dia de Doar na sua comunidade;</p>
                <!-- <img src="assets/mascotes/como-comecou.png" alt=""> -->
            </mat-card-content>
        </mat-card>
        <mat-card>
            <mat-card-content>
                <p>Organize uma ação de incentivo à doação de sangue para os hemocentros da cidade. Você pode buscar
                    apoio de algum artista
                    local para incentivar ainda mais a participação popular, como neste exemplo.</p>
            </mat-card-content>
        </mat-card>
        <mat-card>
            <mat-card-content>
                <p>Confira como foram ações de campanhas como a #DoaSãoJosé, #DoaEsteio, #DoaPenápolis. Entenda como
                    funcionam as campanhas
                    comunitárias e como o poder público pode se envolver para engajar as pessoas para que se mobilizem
                    em prol da própria
                    comunidade.</p>
            </mat-card-content>
        </mat-card>
    </section>
</section>