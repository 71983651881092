<section class="participate">
    <h2>Quem está envolvido?</h2>
    <p>Todo mundo! Celebramos uma ampla gama de participantes d einstituições sem fins lucrativos, de pequenas a grandes
        empresas, pessoas e famílias. Todos tem uma coisa em comum: eles estão comprometidos com a genenrosidade da
        cultura de doação.</p>

    <section class="cards">
        <mat-card [routerLink]="['/ongs']">
            <mat-card-content>
                <div class="image"><img src="assets/mascotes/participe/Participe_Personagem_AMARELO.png" alt=""></div>
                <div class="card-title">Instituições sem fins lucrativos</div>
            </mat-card-content>
        </mat-card>
        <mat-card [routerLink]="['/empresas']">
            <mat-card-content>
                <div class="image"><img src="assets/mascotes/participe/Participe_Personagem_LARANJA.png" alt=""></div>
                <div class="card-title">Empresas</div>
            </mat-card-content>
        </mat-card>
        <mat-card [routerLink]="['/campanhas']">
            <mat-card-content>
                <div class="image"><img src="assets/mascotes/participe/Participe_Personagem_VERDE.png" alt=""></div>
                <div class="card-title">Campanhas comunitárias</div>
            </mat-card-content>
        </mat-card>
        <mat-card [routerLink]="['/gestao-publica']">
            <mat-card-content>
                <div class="image"><img src="assets/mascotes/participe/Participe_Personagem_CELESTE.png" alt=""></div>
                <div class="card-title">Representantes do poder público</div>
            </mat-card-content>
        </mat-card>
        <mat-card [routerLink]="['/pessoas-e-cia']">
            <mat-card-content>
                <div class="image"><img src="assets/mascotes/participe/Participe_Personagem_ROSA.png" alt=""></div>
                <div class="card-title">Amigos, familiares e vizinhos</div>
            </mat-card-content>
        </mat-card>
        <mat-card>
            <a href="https://www.gentilezagenerosidade.org.br/diadedoarkids#:~:text=O%20objetivo%20do%20Dia%20de,e%20implementar%20suas%20pr%C3%B3prias%20iniciativas"
                target="_blank">
                <mat-card-content>
                    <div class="image"><img src="assets/mascotes/participe/Participe_Personagem_VERMELHO.png" alt="">
                    </div>
                    <div class="card-title">Escolas e crianças</div>
                </mat-card-content>
            </a>
        </mat-card>
        <mat-card [routerLink]="['/inscreva-se']">
            <mat-card-content>
                <div class="image"><img src="assets/mascotes/participe/Participe_Personagem_CIANO.png" alt=""></div>
                <div class="card-title">Seja voluntário para aumentar o impacto do Dia de Doar</div>
            </mat-card-content>
        </mat-card>
    </section>

</section>