<section class="become">
    <span class="text">

        <h2>Torne-se um doador neste Dia de Doar</h2>
        <p>
            O Dia de Doar incentiva doações de todos os tipos! A primeira coisa a fazer é reunir sua rede. Doar é muito
            mais
            impactante (e divertido) quando é feito em grupo! Pense nos seus grupos de WhatsApp, em espaços
            colaborativos do
            seu
            bairro, nos grupos de Facebook da sua cidade ou na intranet da sua empresa…
        </p>
        <p>

            Em seguida, convide quem se interessou a construir junto com você estratégias para conscientizar a
            comunidade
            sobre
            a
            causa que estão lutando. Pode ser uma causa local ou uma colaboração para resolver um problema da
            comunidade.
        </p>
    </span>
    <section class="cards">
        <mat-card>
            <mat-card-content>
                <p>Encontre organizações na sua região pelo
                    <a [routerLink]="['/inscreva-se']">
                        Mapa da Generosidade!
                    </a>
                </p>
                <!-- <img src="assets/mascotes/como-comecou.png" alt=""> -->
            </mat-card-content>
        </mat-card>
        <mat-card>
            <mat-card-content>
                <a [routerLink]="['/inspire-se']">
                    <p>Encontre histórias inspiradoras</p>
                </a>
            </mat-card-content>
        </mat-card>
        <mat-card>
            <mat-card-content>
                <a [routerLink]="['/inscreva-se']">
                    <p>Compartilhe sua iniciativa</p>
                </a>
            </mat-card-content>
        </mat-card>
        <mat-card>
            <mat-card-content>
                <a href="https://forms.gle/R3UNT3YpfZjPdx6p8">
                    <p>Seja um voluntário digital do Dia de Doar</p>
                </a>
            </mat-card-content>
        </mat-card>
    </section>
</section>