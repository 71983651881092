import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PublicComponent } from './public.component';
import { SharedModule } from '../shared/shared.module';
import { PublicHeroComponent } from './components/public-hero/public-hero.component';
import { PublicEngageComponent } from './components/public-engage/public-engage.component';
import { MatCardModule } from '@angular/material/card';
import { PublicIdeasComponent } from './components/public-ideas/public-ideas.component';
import { RouterModule } from '@angular/router';
import { MatDividerModule } from '@angular/material/divider';



@NgModule({
  declarations: [
    PublicComponent,
    PublicHeroComponent,
    PublicEngageComponent,
    PublicIdeasComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    MatCardModule,
    RouterModule,
    MatDividerModule
  ]
})
export class PublicModule { }
