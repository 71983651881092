<section class="mobilize">
    <h2>Por que sua empresa deveria mobilizar
        uma campanha do Dia de Doar?</h2>
    <p>
        Ser público sobre seus valores é bom para o seu negócio e bom para o mundo. Alguns fatos rápidos sobre marcas e
        retribuição:
    </p>

    <div class="list">
        <ul>
            <li>
                <strong>
                    64%
                </strong>
                das pessoas se consideram “compradores movidos por causas”, de acordo com um estudo de 2020 da Edelman –
                o que
                significa que escolherão, trocarão, evitarão ou cancelarão uma marca com base em sua postura em questões
                sociais.
                <a href="https://www.edelman.com/sites/g/files/aatuss191/files/2020-06/2020%20Edelman%20Trust%20Barometer%20Specl%20Rept%20Brand%20Trust%20in%202020.pdf"
                    target="_blank">
                    (fonte)
                </a>
            </li>
            <li>
                <strong>
                    90%
                </strong>
                são mais propensos a experimentar um produto de uma pessoa ou empresa que se posiciona sobre questões
                sociais e
                políticas.
                <a href="https://www.mww.com/blogs/brands-should-take-a-stand-on-societal-issues-but-avoid-goodwashing-study-says/"
                    target="_blank">
                    (fonte)
                </a>
            </li>
            <li>
                <strong>
                    80%
                </strong>
                estão dispostos a pagar MAIS por um produto ou serviço de uma marca que divulga publicamente seus
                valores sociais e
                políticos
                <a href="https://www.mww.com/blogs/brands-should-take-a-stand-on-societal-issues-but-avoid-goodwashing-study-says/"
                    target="_blank">
                    (fonte)
                </a>
            </li>
            <li>
                <strong>85%</strong>
                dos clientes respondem mais favoravelmente a empresas que apoiam causas com as quais se preocupam.
                <a href="http://ppqty.com/2010_Cone_Study.pdf" target="_blank">
                    (fonte)
                </a>
            </li>
            <li>
                O voluntariado em equipe ajuda a criar conexões entre os funcionários. É uma ótima atividade de
                engajamento corporativo,
                ampliando a criatividade e a felicidade, e ainda reduzindo a rotatividade de funcionários em
                <strong>50%</strong>.
                <a href="https://www.3blmedia.com/News/Research-Finds-Doing-Good-Can-Generate-Profits-If-Done-Right"
                    target="_blank">
                    (fonte)
                </a>
            </li>

            <li>
                79% dos funcionários preferem trabalhar para empresas socialmente responsáveis e acham importante que as
                empresas
                correspondam às suas doações.
                <a href="https://www.charities.org/facts-statistics-workplace-giving-matching-gifts-and-volunteer-programs"
                    target="_blank">
                    (fonte)
                </a>
            </li>

            <li>
                Assumir uma posição na transformação social e contrubuir com causas sociais cria confiança, conexão e
                relacionamentos
                duradouros. Isso demonstra que você tem valores e objetivos além da receita, e é uma expressão de
                autenticidade. Não há
                melhor momento do que o Dia de Doar para marcas, empresas e consumidores promoverem mudanças em suas
                comunidades.
            </li>
        </ul>
    </div>
</section>