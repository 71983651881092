<section class="no-profit">
    <section class="left-container">
        <h2>Dia de Doar nas organizações sem fins lucrativos e comunitárias</h2>

        <p>Organizações sem fins lucrativos, fundações, projetos sociais e redes de apoio mútuo de todo o mundo têm
            usado o
            GivingTuesday como um momento de celebração com suas comunidades desde 2012. Desde ampliar o reconhecimento
            da sua
            missão até captar recursos, reunindo voluntários e construindo um movimento para sua causa. Existem centenas
            de maneiras
            diferentes de participar do Dia de Doar!
        </p>
        <p>
            Existem centenas de maneiras diferentes de participar do Dia de Doar! Confira alguns dos materiais que temos
            disponíveis para sua utilização:
        </p>
        <!-- <button mat-button> Conhecer mais</button> -->
    </section>
    <section class="right-container">
        <img src="assets/mascotes/homeapresentacao.png" alt="">
    </section>
</section>