import { Component } from '@angular/core';

@Component({
  selector: 'app-simple-ways',
  templateUrl: './simple-ways.component.html',
  styleUrls: ['./simple-ways.component.scss']
})
export class SimpleWaysComponent {

}
