import { Component } from '@angular/core';

@Component({
  selector: 'app-comunity-benefits',
  templateUrl: './comunity-benefits.component.html',
  styleUrls: ['./comunity-benefits.component.scss']
})
export class ComunityBenefitsComponent {

}
