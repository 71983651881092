import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OngsComponent } from './ongs.component';
import { SharedModule } from '../shared/shared.module';
import { OngHeroComponent } from './components/ong-hero/ong-hero.component';
import { MatButton, MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';



@NgModule({
  declarations: [
    OngsComponent,
    OngHeroComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    MatButtonModule,
    MatDividerModule
  ]
})
export class OngsModule { }
