<section class="reports">
    <h2>Confira nossos relatórios de atividades</h2>
    <span>

        <section class="cards">
            <ng-container *ngFor="let item of resultsService.results$ | async; let i = index">

                <ng-container *ngIf="item.descricao">
                    <mat-card>
                        <mat-card-header>
                            <mat-card-header-text>

                                <h2 class="blue">
                                    <strong>

                                        {{item.ano}}
                                    </strong>
                                </h2>
                            </mat-card-header-text>
                        </mat-card-header>
                        <mat-card-content>
                            <p>{{item.descricao}}</p>
                        </mat-card-content>
                        <mat-card-footer *ngIf="!!item.url_relatorio">
                            <a mat-button [href]="item.url_relatorio" class="round background-dark-blue">Acessar
                                Relatório
                                {{item.ano}}
                                ></a>

                        </mat-card-footer>
                    </mat-card>
                </ng-container>

            </ng-container>

        </section>
        <section class="banner">
            <mat-card>
                <mat-card-content>
                    <p>Conheça nosso Programa de Dados, nossa metodologia e métricas.</p>
                    <img src="assets/mascotes/personagemLaranja.png" alt="">
                </mat-card-content>
                <mat-card-footer>
                    <a mat-button class="round background-dark-yellow" [routerLink]="['/dados']">Programa de Dados
                        ></a>

                </mat-card-footer>
            </mat-card>

        </section>
    </span>
</section>