import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NewsPost } from '../../models/posts.model';
import { Firestore, Timestamp, collection, doc, getDoc } from '@angular/fire/firestore';
import { NewsCategoryLabel } from '../../services/category-news.service';

@Component({
  selector: 'app-news-deatil',
  templateUrl: './news-deatil.component.html',
  styleUrls: ['./news-deatil.component.scss']
})
export class NewsDeatilComponent implements OnInit {


  public NewsCategoryLabel = NewsCategoryLabel;

  id!: string;

  post!: NewsPost;

  constructor(private route: ActivatedRoute, protected fireStore:Firestore){

    this.route.params.subscribe(params=>{
      this.id = atob(params['id']);
      // console.log(this.id)

      // this.post = params as unknown as NewsPost;
      this.getPostById(this.id);
    })
  }

  ngOnInit(): void {
  }

  async getPostById(id: string){
    const col = doc(this.fireStore, 'news', id);
    const PostRef = await getDoc(col)
    this.post = PostRef.data() as unknown as NewsPost;
    // console.log(this.post)
  }



  public timestampToDate(ts: Timestamp){
    return new Timestamp(ts.seconds, ts.nanoseconds).toDate()
  }
}
