<section class="faq-hero">
    <section class="title">
        <h1>
            <strong>

                FAQ
            </strong>
        </h1>
        <p>Tem alguma dúvida sobre o Dia de Doar?</p>
        <p>Nós temos as respostas!</p>
        <!-- <button class="round background-dark-blue" mat-button>Assista como funciona</button> -->
    </section>
    <section class="pictures">
        <img src="assets/mascotes/faq-hero-mascote.png" alt="picture">
    </section>
</section>