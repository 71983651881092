import { Component } from '@angular/core';
import { Timestamp } from '@angular/fire/firestore';
import { ClockService } from 'src/app/shared/services/clock.service';

@Component({
  selector: 'app-about-intro',
  templateUrl: './about-intro.component.html',
  styleUrls: ['./about-intro.component.scss']
})
export class AboutIntroComponent {
  date: Date = new Date();

  constructor(private clockService: ClockService){
    this.clockService.getClock()
    this.clockService.localClock.subscribe(res=>{
      if(res) this.date = res.data.toDate();
    });
  }
}
