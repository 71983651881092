import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ShowAmbassadorComponent } from 'src/app/shared/components/show-ambassador/show-ambassador.component';
import { Embaixador } from 'src/app/shared/models/dashboard.model';
import { DashboardService } from 'src/app/shared/services/dashboard.service';

@Component({
  selector: 'app-ambassador-team',
  templateUrl: './ambassador-team.component.html',
  styleUrls: ['./ambassador-team.component.scss']
})
export class AmbassadorTeamComponent {
  ambassadors!: Embaixador[];

  constructor(private dashboardService:DashboardService, public dialog: MatDialog){
    this.dashboardService.dashboard.subscribe(res=>{
      if(res)this.ambassadors = res.embaixadores
    })
  }

  openDialog(ambassador: Embaixador){
    this.dialog.open(ShowAmbassadorComponent,
      {
      width: '80vw',
      maxWidth:'100vw',
      data: ambassador,
    });

  }

}
