<section class="quick-facts">
    <h2>Fatos rápidos sobre o Dia de Doar</h2>
    <p>O Dia de Doar faz parte de um crescente movimento global de incentivo à generosidade que impulsiona doações e
        ações
        coletivas em comunidades ao redor do mundo.</p>
    <p>
        O Dia de Doar faz parte do GivingTuesday, movimento criado em 2012 com uma ideia simples: um dia que incentiva
        as
        pessoas a fazerem o bem. Nos últimos dez anos, essa ideia se transformou em um movimento global que inspira
        centenas de
        milhões de pessoas a doar, colaborar e celebrar a generosidade o ano todo.
    </p>

    <p>
        O GivingTuesday inspira generosidade em todo o mundo através de uma rede distribuída de líderes. Existem
        movimentos
        nacionais oficiais do GivingTuesday em 90 países, liderados por uma rede global de líderes empreendedores
        apaixonados.
        Em nível de base, pessoas e organizações participam do GivingTuesday em todos os países do mundo.
    </p>
    <p>
        O Dia de Doar cria um impacto global impulsionado pela ação local. Somente no Brasil, em 2022 foram 72 campanhas
        comunitárias que reúnem milhares de organizações sem fins lucrativos, fundações, organizações de base, pequenas
        empresas
        e indivíduos generosos para doar. Por meio do #DiadeDoarKids, os jovens estão liderando mudanças e inspirando
        ações em
        todo o mundo. 
    </p>
    <img src="assets/hero/about-hero.png" alt="picture">
    <p>O Dia de Doar está gerando um aumento líquido nas doações. O movimento continua a crescer ano após ano em volume
        de
        doações, alcance e impacto – impulsionando o aumento de doações e mudança de comportamento. Em 2022, cerca de R$
        2,3
        milhões foram doados em 24 horas apenas no Brasil, um aumento de 53% em relação ao ano anterior. Em 29 de
        novembro de
        2022, 28 milhões de pessoas foram impactadas pela mensagem do Dia de Doar somente pelas redes sociais.</p>
    <p>
        O Dia de Doar é um centro de conhecimento, aprendizado e, por fim, inovação em torno da generosidade. Nosso
        Programa de
        Dados trabalha junto com a Fundação José Luis Egydio Setubal e grande parte das plataformas de transação de
        doação
        brasileira, juntos para entender os motivadores e impactos da generosidade, explorar comportamentos e padrões de
        doação
        e usar dados para inspirar mais doações em todo o mundo. Junto com o <a
            href="https://www.givingtuesday.org/data-commons" target="_blank">GivingTuesday Data Commons</a>, são mais
        de 300
        parceiros contribuintes e 50 laboratórios de dados globais, a iniciativa é a maior colaboração filantrópica de
        dados já
        criada.
    </p>
    <p>
        As pessoas podem mostrar sua generosidade de várias maneiras durante o Dia de Doar. Seja ajudando um vizinho,
        buscando
        doações para resolver um problema pontual, compartilhando uma habilidade ou encontrando oportunidades virtuais
        de
        voluntariado com suas causas favoritas - todos têm algo a oferecer e toda doação conta.
    </p>
</section>