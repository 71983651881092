import { Component } from '@angular/core';

@Component({
  selector: 'app-companies-quick-facts',
  templateUrl: './companies-quick-facts.component.html',
  styleUrls: ['./companies-quick-facts.component.scss']
})
export class CompaniesQuickFactsComponent {

}
