<section class="follow">
    <section class="image">
        <img src="assets/mascotes/personagemAzul.png" alt="">
    </section>

    <section class="text">
        <h2>Quer ser líder do Dia de Doar?</h2>
        <p>Siga-nos para mais informações sobre o movimento</p>
        <div class="cards">
            <a href="https://instagram.com/diadedoar/" target="_blank">
                <mat-icon class="icon" svgIcon="instagram"></mat-icon>
            </a>
            <a href="https://www.linkedin.com/showcase/diadedoar/" target="_blank">
                <mat-icon class="icon" svgIcon="linkedin"></mat-icon>
            </a>
            <a href="https://youtube.com/diadedoarbrasil" target="_blank">
                <mat-icon class="icon" svgIcon="youtube"></mat-icon>
            </a>
            <a href="https://twitter.com/diadedoar" target="_blank">
                <mat-icon class="icon" svgIcon="twitter"></mat-icon>
            </a>
            <a href="https://facebook.com/diadedoar/" target="_blank">
                <mat-icon class="icon" svgIcon="facebook"></mat-icon>
            </a>
        </div>
    </section>
</section>