import { Component } from '@angular/core';
import { Timestamp } from '@angular/fire/firestore';
import { ClockService } from 'src/app/shared/services/clock.service';

@Component({
  selector: 'app-campaign-hero',
  templateUrl: './campaign-hero.component.html',
  styleUrls: ['./campaign-hero.component.scss']
})
export class CampaignHeroComponent {
  now: Date = new Date();
  date: Date = new Date();

  constructor(private clockService: ClockService){
    this.clockService.getClock()
    this.clockService.localClock.subscribe(res=>{
      if(res) this.date = new Timestamp(res.data.seconds, res.data.nanoseconds).toDate()
    });
  }

}
