<section class="countdown" *ngIf="date">
    <span class="container">
        <span class="number">{{daysTo}}</span>
        <span class="title">Dias</span>

        <span class="number">{{hoursTo}}</span>
        <span class="title">Horas</span>

        <span class="number">{{minutesTo}}</span>
        <span class="title">Minutos</span>
    </span>

    <span class="description">Até o próximo Dia de Doar na <span class="date">{{date | date:'fullDate'}}</span></span>
</section>