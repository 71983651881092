import { Injectable } from '@angular/core';
import { Firestore, Timestamp, addDoc, collection, doc, onSnapshot, updateDoc } from '@angular/fire/firestore';
import { BehaviorSubject } from 'rxjs';

interface clocks{
  data: Date | Timestamp,
  cacheDuration: number,
  lastUpdate: Date | Timestamp
}

@Injectable({
  providedIn: 'root'
})
export class ClockService {

  private _localClock = new BehaviorSubject<any>(null);
  public localClock = this._localClock.asObservable();

  constructor(private fireStore:Firestore) {
    this.getClock();
   }
  private clockCollection = collection(this.fireStore, 'clock');

  getClock(){
    onSnapshot(this.clockCollection, (res)=>{
      this._localClock.next(res.docs.map(doc=>{
        return {...doc.data(), id:doc.id};
      })[0]);
    });
  }

  createOrUpdateClock(data: clocks){
    if(this._localClock.value){
      // console.log(this._localClock.value)
      this.updateclock(data, this._localClock.value.id);
    } else{
      this.createClock(data);
    }
  }

  private async createClock(data:clocks){
    await addDoc(this.clockCollection,{...data});
  }

  private async updateclock(data:clocks, id:string){
    const d = doc(this.clockCollection, id);
    await updateDoc(d,{...data} );
  }
}
