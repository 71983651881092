import { Component } from '@angular/core';

@Component({
  selector: 'app-public-ideas',
  templateUrl: './public-ideas.component.html',
  styleUrls: ['./public-ideas.component.scss']
})
export class PublicIdeasComponent {

}
