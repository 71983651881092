import { Component, Input } from '@angular/core';
import { DashboardService } from '../../services/dashboard.service';
import { Doador } from '../../models/dashboard.model';

interface prio{
  [key: string]: number
}

@Component({
  selector: 'app-donors',
  templateUrl: './donors.component.html',
  styleUrls: ['./donors.component.scss']
})
export class DonorsComponent {

  @Input() description: boolean = false;
  donors!: any;
  donorPriority:prio = {
    'Amado': 1,
    'Fantástico': 2,
    'Maravilha': 3,
    'Engajado': 4,
    'Apoio Institucional': 5
  }

  constructor(private dashboardService:DashboardService){
    this.dashboardService.dashboard.subscribe(res=>{
      if(res){
          this.donors = this.groupBy(res.doadores, 'categoria');
          // console.log(this.donors)
        }
    })
  }

  groupBy(input:any, key:any){
  return input.reduce((acc:any, currentValue:any) => {
    let groupKey = currentValue[key];
    if (!acc[groupKey]) {
      acc[groupKey] = [];
    }
    acc[groupKey].push(currentValue);
    return acc;
  }, {});
};

get keys(){
  // console.log(Object.keys(this.donors).sort((category, next)=>this.donorPriority[category] - this.donorPriority[next]))
  return Object.keys(this.donors).sort((category, next)=>this.donorPriority[category] - this.donorPriority[next])
}


}
