<app-about-hero></app-about-hero>
<app-about-intro></app-about-intro>
<app-principal-results></app-principal-results>
<app-how-it-started></app-how-it-started>
<app-results></app-results>
<app-detail text="Quer conhecer mais detalhes?" route="/resultados" label="Acessar resultados"></app-detail>
<app-kids></app-kids>
<app-follow></app-follow>
<app-participate></app-participate>
<app-leader></app-leader>
<app-category-news></app-category-news>
<app-common-questions></app-common-questions>
<app-donors [description]="true"></app-donors>