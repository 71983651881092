<ng-container *ngIf="post">
    <section class="post">

        <section class="banner">
            <img [src]="post.banner" alt="">
            <!-- <mat-chip></mat-chip> -->
            <!-- <ng-container *ngif="post.categorias.length"> -->
            <span>

                <mat-chip disabled *ngFor="let category of post.categorias">{{NewsCategoryLabel[category] ||
                    category}}</mat-chip>
            </span>
            <!-- </ng-container> -->
        </section>
        <section class="title">
            <h1>{{post.titulo}}</h1>
            <h3>{{timestampToDate(post.data) | date:'fullDate'}}</h3>
        </section>
        <section class="content">
            <div [innerHTML]="post.conteudo"></div>
        </section>
    </section>
</ng-container>