import { Component, Input } from '@angular/core';
import { PageItem } from '../../layout.component';

@Component({
  selector: 'app-sidenav-list',
  templateUrl: './sidenav-list.component.html',
  styleUrls: ['./sidenav-list.component.scss']
})
export class SidenavListComponent {
  @Input() pages!:PageItem[]

}
