import { Injectable } from '@angular/core';
import { Firestore, collection, onSnapshot, addDoc, doc, updateDoc } from '@angular/fire/firestore';
import { BehaviorSubject } from 'rxjs';
import { Dashboard } from '../models/dashboard.model';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  private _dashboard = new BehaviorSubject<any>(null);
  public dashboard = this._dashboard.asObservable();

  constructor(private fireStore:Firestore) {
    this.getDashboard();
   }
  private dashboardCollection = collection(this.fireStore, 'dashboard');

  getDashboard(){
    onSnapshot(this.dashboardCollection, (res)=>{
      this._dashboard.next(res.docs.map(doc=>{
        return {...doc.data(), id:doc.id};
      })[0]);
    });
  }

  createOrUpdateDashboard(data: Dashboard){
    if(this._dashboard.value){
      // console.log(this._localDashboard.value)
      this.updateDashboard(data, this._dashboard.value.id);
    } else{
      this.createDashboard(data);
    }
  }

  private async createDashboard(data:Dashboard){
    await addDoc(this.dashboardCollection, data as any);
  }

  private async updateDashboard(data:Dashboard, id:string){
    const d = doc(this.dashboardCollection, id);
    await updateDoc(d,data as any);
  }
}
