<section class="depositions">
    <section class="header">
        <span>

            <h2>Depoimentos</h2>
            <h3>São muitas formas de se envolver:</h3>
        </span>
    </section>
    <section class="carousel" *ngIf="slides.length">
        <app-swiper [slides]="slides"></app-swiper>
    </section>
</section>