import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CampaignComponent } from './campaign.component';
import { SharedModule } from '../shared/shared.module';
import { MatCardModule } from '@angular/material/card';
import { CampaignHeroComponent } from './components/campaign-hero/campaign-hero.component';
import { ComunityCampaignComponent } from './components/comunity-campaign/comunity-campaign.component';
import { ComunityLeadComponent } from './components/comunity-lead/comunity-lead.component';
import { ComunityBenefitsComponent } from './components/comunity-benefits/comunity-benefits.component';
import { ComunityWhatMakesComponent } from './components/comunity-what-makes/comunity-what-makes.component';



@NgModule({
  declarations: [
    CampaignComponent,
    CampaignHeroComponent,
    ComunityCampaignComponent,
    ComunityLeadComponent,
    ComunityBenefitsComponent,
    ComunityWhatMakesComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    MatCardModule
  ]
})
export class CampaignModule { }
