import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PressComponent } from './press.component';
import { SharedModule } from '../shared/shared.module';
import { PressHeroComponent } from './components/press-hero/press-hero.component';
import { PressPresentationComponent } from './components/press-presentation/press-presentation.component';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';



@NgModule({
  declarations: [
    PressComponent,
    PressHeroComponent,
    PressPresentationComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    MatButtonModule,
    MatDividerModule
  ]
})
export class PressModule { }
