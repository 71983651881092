import { Component } from '@angular/core';

@Component({
  selector: 'app-results-hero',
  templateUrl: './results-hero.component.html',
  styleUrls: ['./results-hero.component.scss']
})
export class ResultsHeroComponent {

}
