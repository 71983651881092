<mat-dialog-title>
    <section class="title">
        <p>{{timestampToDate(data.data) | date:'fullDate'}}</p>
        <h3>{{data.titulo}}</h3>
    </section>
</mat-dialog-title>
<mat-dialog-content>
    <div [innerHTML]="data.conteudo"></div>
</mat-dialog-content>
<section class="post" *ngIf="data as post">
</section>