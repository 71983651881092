import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CompaniesComponent } from './companies.component';
import { SharedModule } from '../shared/shared.module';
import { CompaniesHeroComponent } from './components/companies-hero/companies-hero.component';
import { MobilizeComponent } from './components/mobilize/mobilize.component';
import { CompaniesQuickFactsComponent } from './components/companies-quick-facts/companies-quick-facts.component';
import { CompaniesHowComponent } from './components/companies-how/companies-how.component';
import { MatCardModule } from '@angular/material/card';



@NgModule({
  declarations: [
    CompaniesComponent,
    CompaniesHeroComponent,
    MobilizeComponent,
    CompaniesQuickFactsComponent,
    CompaniesHowComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    MatCardModule
  ]
})
export class CompaniesModule { }
