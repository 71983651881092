import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SubscribeComponent } from './subscribe.component';
import { WelcomeComponent } from './components/welcome/welcome.component';
import { SharedModule } from '../shared/shared.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxMaskDirective } from 'ngx-mask';
import { MatSelectModule } from '@angular/material/select';
import { MatCardModule } from '@angular/material/card';
import { MatButton, MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';
import { ArrivedNowComponent } from './components/arrived-now/arrived-now.component';
import { MatListModule } from '@angular/material/list';



@NgModule({
  declarations: [
    SubscribeComponent,
    WelcomeComponent,
    ArrivedNowComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    NgxMaskDirective,
    MatSelectModule,
    MatCardModule,
    MatButtonModule,
    RouterModule,
    MatListModule
  ],
  exports: [

    ArrivedNowComponent
  ]
})
export class SubscribeModule { }
