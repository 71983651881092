<section class="presentation">
    <section class="left-container">
        <p>O <strong>Dia de Doar</strong> é um movimento para promover a generosidade e a cultura de doação. É uma
            mobilização que
            promove um
            país
            mais doador e empático por meio da conexão de pessoas. E faz isso celebrando o prazer que é doar, e
            impulsionando o
            hábito de doar o tempo todo.
        </p>
        <p>
            Como movimento, não é centralizado: a marca, os materiais de campanha, manuais
            de
            apoio,
            etc, são todos disponibilizados para que cada um que queira participar possa se apropriar e criar sua
            própria
            iniciativa.
        </p>
        <p> Quem faz o Dia de Doar acontecer é quem está do outro lado da ação - seja doando ou fazendo uma
            ação
            para
            estimular a doação de toda a comunidade.
            <strong>
                Compartilhe com a hashtag #diadedoar nas mídias sociais.
            </strong>
        </p>
        <a class="round background-light-blue" mat-button [routerLink]="['/sobre']">Saiba mais sobre nossa história
            ></a>
    </section>
    <section class="right-container">
        <img src="assets/mascotes/homeapresentacao.png" alt="">
    </section>
</section>