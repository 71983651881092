<ng-container>
    <section class="news" *ngIf="categoryNewsService.posts$ | async as posts">
        <ng-container *ngIf="posts.length > 0">
            <section class="header">
                <h2>Últimas notícias</h2>

                <button mat-button class="round background-light-yellow" [routerLink]="'/blog'">Ver mais publicações
                    ></button>
            </section>

            <section class="cards">
                <ng-container *ngFor="let post of posts; let i = index">
                    <mat-card *ngIf="i < 3">
                        <mat-card-header *ngIf="post.banner">
                            <img class="card-image" [src]="post.banner" alt="">
                            <mat-chip disabled *ngFor="let category in post.categorias">{{NewsCategoryLabel[category] ||
                                category}}</mat-chip>
                        </mat-card-header>
                        <mat-card-content>
                            <span class="content">
                                <p>{{timestampToDate(post.data) | date:'fullDate'}}</p>
                                <h3>{{post.titulo}}</h3>
                                <div [innerHTML]="post.conteudo"></div>
                            </span>
                        </mat-card-content>
                        <mat-card-footer>
                            <a class="round" [routerLink]="'/noticia/'+btoa(post.id)">Ler mais</a>
                        </mat-card-footer>
                    </mat-card>
                </ng-container>
                <!-- <mat-card>
                    <mat-card-header>
                        <img class="card-image" src="https://blog.iprocess.com.br/wp-content/uploads/2021/11/placeholder.png"
                            alt="">
                    </mat-card-header>
                    <mat-card-content>
                        <p>13 de janeiro</p>
                        <h3>Especialistas compartilharam estratégias para fortalecer ONGs no evento Dia de Doar</h3>
                        <p>Veja as instituições participantes do Dia de Doar e encontre sua causa</p>
                        <button mat-button>Ler mais</button>
                    </mat-card-content>
                </mat-card>
                <mat-card>
                    <mat-card-header>
                        <img class="card-image" src="https://blog.iprocess.com.br/wp-content/uploads/2021/11/placeholder.png"
                            alt="">
                    </mat-card-header>
                    <mat-card-content>
                        <p>13 de janeiro</p>
                        <h3>Especialistas compartilharam estratégias para fortalecer ONGs no evento Dia de Doar</h3>
                        <p>Veja as instituições participantes do Dia de Doar e encontre sua causa</p>
                        <button mat-button>Ler mais</button>
                    </mat-card-content>
                </mat-card> -->
            </section>
        </ng-container>

    </section>
</ng-container>