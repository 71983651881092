import { Component } from '@angular/core';

@Component({
  selector: 'app-comunity-lead',
  templateUrl: './comunity-lead.component.html',
  styleUrls: ['./comunity-lead.component.scss']
})
export class ComunityLeadComponent {

}
