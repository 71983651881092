<section class="data-hero">
    <section class="title">
        <h1 class="text-box">
            Usando dados para
            <span class="green">
                promover
            </span>
            um
            <span class="blue">
                mundo mais generoso
            </span>
        </h1>
        <p>O Programa de Dados do Dia de Doar fornece ao setor social o que a indústria e o comércio há muito se
            beneficia: dados
            para conduzir uma melhor tomada de decisões, fortalecer organizações da sociedade civil e acelerar a
            inovação social de
            forma equitativa e descentralizada.</p>
        <p>Para isso, trabalhamos com parceiros em todos os setores e fronteiras para:</p>
        <ul>
            <li>entender os motivos e impactos de todos os tipos de doação</li>
            <li>analisar os efeitos das redes sociais e ações coletivas</li>
            <li>explorar comportamentos e padrões de doação</li>
            <li>construir e promover ferramentas práticas de promoção do Dia de Doar</li>
            <li>e usar estes dados para inspirar mais doações em todo o Brasil</li>
        </ul>
    </section>
    <section class="pictures">
        <img src="assets/banners/Dados.png" alt="picture">
    </section>
</section>