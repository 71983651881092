import { Component } from '@angular/core';

@Component({
  selector: 'app-ambassadors',
  templateUrl: './ambassadors.component.html',
  styleUrls: ['./ambassadors.component.scss']
})
export class AmbassadorsComponent {

}
